/* eslint-disable max-lines */
/** @format */

import { humanize } from "@roadflex/utils";

// NOTE: all constants should be in CAPITAL_CASE with UNDERSCORE for word separation.
const { NEXT_PUBLIC_CDN_URL } = process.env;

export const STRIPE_API_VERSION = "2024-06-20";

export const MINUTE = 60 * 1000;
export const HOUR = 60 * MINUTE;
export const SECONDS_IN_DAY = 24 * HOUR;
export const SECONDS_IN_WEEK = 7 * SECONDS_IN_DAY;
export const MAX_AGE = 30 * SECONDS_IN_DAY;

export const MINIMUM_FUEL_SPENDING = 50000;
export const MINIMUM_CHARGE_AMOUNT = 50;
export const MINIMUM_MANUAL_AMOUNT = 2500;
export const AUTO_RELOAD_THRESHOLD_AMOUNT = 5000;
export const PREPAID_DEPOSIT_SETUP_FEE = 2500;

export const FIXED_LATE_FEE = 3500;
export const DAYS_OVERDUE_LIMIT = 28;
export const MAX_REFERRAL_BONUSES = 50;
export const HIGH_DISTANCE = 500000;

export const HIGH_RISK_DAYS_THRESHOLD = 28;
export const DEFAULT_USER_DAYS_THRESHOLD = 60;
export const RANDOM_CHARACTER_COMBINATION = "ABCDEFGHJKLMPQRT23456789";
export const RANDOM_TXN_CHAR_COMBINATION =
  "ABCDEFGHIJKLMNPQRSTUVWXYZabcdefghijklmnpqrstuvwxyz123456789";
export const PREPAID_CREDIT_LIMIT = 1000000;
export const SUBSCRIPTION_NAMES = ["vivek-test-topic-sub1", "MySub"];
export const ROADFLEX_DEFAULT_IP = "73.15.98.134";
export const LEADS_COMMUNICATION_FOLDER_NAME = "leads_communication";
export const LEADS_FOLDER_NAME = "leads";

export const MERCHANT_CATEGORY_CODES = {
  [`Automated Fuel Dispensers`]: 5542,
};
export const OPERATIONS_ADMIN_EMAIL = "operations@roadflex.com";
export const GREG_EMAIL = "gregsoh38+demo_account@gmail.com";
export const DUMMY_PHONE_NUMBER = "2222222222";
export const GREG_DEFAULT_LOGIN_OTP = "877870";
export const DENNIS_EMAIL = "hchang620@gmail.com";
export const SENDGRID_EMAIL_TEMPLATE_ID = "d-9516e35a142a4fff8d35f1c5830f24c7";
export const DEVELOPERS_TEST_SLACK_CHANNEL = "developers-test";

export const LEND_FLOW_PRODUCT_DATA = [
  // "middesk",
  "ocrolus_cfa",
  "experian_uccs", // Not using
  "experian_bankruptcies", // Need to review this as are already getting bankruptcies from middesk
  "experian_judgments",
  "experian_liens",
  "experian_intelliscore",
  "experian_fsr",
];

export const LENDFLOW_PENDING_STATUSES = [
  "PENDING",
  "PROCESSING",
  "PENDING_AT_LENDFLOW",
];
export const PENDING_AT_LENDFLOW = "Pending at lendFlow";
export const FAILED = "Failed";
export const SUCCESS = "Success";
export const PENDING = "Pending";
export const NOT_YET_STARTED = "Not yet started";
export const STARTED = "Started";

export const STATE_CODES = [
  { label: "Alabama", value: "AL" },
  { label: "Arizona", value: "AZ" },
  { label: "Arkansas", value: "AR" },
  { label: "California", value: "CA" },
  { label: "Colorado", value: "CO" },
  { label: "Connecticut", value: "CT" },
  { label: "Delaware", value: "DE" },
  { label: "Florida", value: "FL" },
  { label: "Georgia", value: "GA" },
  { label: "Idaho", value: "ID" },
  { label: "Illinois", value: "IL" },
  { label: "Indiana", value: "IN" },
  { label: "Iowa", value: "IA" },
  { label: "Kansas", value: "KS" },
  { label: "Kentucky", value: "KY" },
  { label: "Louisiana", value: "LA" },
  { label: "Maine", value: "ME" },
  { label: "Maryland", value: "MD" },
  { label: "Massachusetts", value: "MA" },
  { label: "Michigan", value: "MI" },
  { label: "Minnesota", value: "MN" },
  { label: "Mississippi", value: "MS" },
  { label: "Missouri", value: "MO" },
  { label: "Montana", value: "MT" },
  { label: "Nebraska", value: "NE" },
  { label: "Nevada", value: "NV" },
  { label: "New Hampshire", value: "NH" },
  { label: "New Jersey", value: "NJ" },
  { label: "New Mexico", value: "NM" },
  { label: "New York", value: "NY" },
  { label: "North Carolina", value: "NC" },
  { label: "North Dakota", value: "ND" },
  { label: "Ohio", value: "OH" },
  { label: "Oklahoma", value: "OK" },
  { label: "Oregon", value: "OR" },
  { label: "Pennsylvania", value: "PA" },
  { label: "Rhode Island", value: "RI" },
  { label: "South Carolina", value: "SC" },
  { label: "South Dakota", value: "SD" },
  { label: "Tennessee", value: "TN" },
  { label: "Texas", value: "TX" },
  { label: "Utah", value: "UT" },
  { label: "Vermont", value: "VT" },
  { label: "Virginia", value: "VA" },
  { label: "Washington", value: "WA" },
  { label: "West Virginia", value: "WV" },
  { label: "Wisconsin", value: "WI" },
  { label: "Wyoming", value: "WY" },
];

export const FLEET_TYPES = [
  {
    label: "Heavy (Trucking fleet, diesel only)",
    value: "HEAVY",
  },
  {
    label: "Mixed (Fleet operating both light and heavy vehicles)",
    value: "MIXED",
  },
  {
    label: "Light (Fleet operating local routes, unleaded and/or diesel)",
    value: "LIGHT",
  },
];

export const COLOR_SCHEME_OPTIONS = [
  { label: "Default", value: "DEFAULT" },
  { label: "Cool and Fresh", value: "COOL_AND_FRESH" },
  { label: "Futuristic", value: "FUTURISTIC" },
  { label: "Natural and Earthy", value: "NATURAL_AND_EARTHY" },
];

export const DISPLAY_TIME_OPTIONS = [
  { label: "User (User's time zone)", value: "USER" },
  { label: "PST", value: "PST" },
  { label: "CT", value: "CT" },
  { label: "EST", value: "EST" },
  { label: "UTC", value: "UTC" },
];

export const CREDIT_CYCLE = [
  { label: "Weekly", value: "WEEKLY" },
  { label: "Monthly", value: "MONTHLY" },
];

export const CREDIT_CYCLE_TYPE = [
  { label: "Weekly", value: "WEEKLY" },
  { label: "Monthly", value: "MONTHLY" },
];

enum ENUM_ADMIN_ROLES {
  SUPERADMIN = "SUPERADMIN",
  UNDERWRITER = "UNDERWRITER",
  CUSTOMER_SUPPORT = "CUSTOMER_SUPPORT",
  SALES = "SALES",
  COLLECTIONS = "COLLECTIONS",
  OPERATIONS = "OPERATIONS",
}

export const ADMIN_ROLES = Object.keys(ENUM_ADMIN_ROLES).map((key) => {
  return {
    label: humanize(key),
    value: key,
  };
});

export const ADMIN_SCOPES = {
  //pages
  dashboard: "dashboard",
  applications: "applications",
  applicationsPendingDeposit: "applications-pending-deposit",
  approvedCustomers: "approved-customers",
  customerInformation: "customer-information",
  payments: "payments",
  globalSettings: "global-settings",
  notifications: "notifications",
  blogs: "blogs",
  risks: "risks",
  collections: "collections",
  scripts: "scripts",
  dataAnalysis: "data-analysis",
  activityLogs: "activity-logs",
  team: "team",
  accountSettings: "account-settings",
  //processes
  deleteApplications: "delete-applications",
  editApplications: "edit-applications",
  moveToApprove: "move-to-approve",
  moveToDecline: "move-to-decline",
  movetoWaitingForApproval: "move-to-waiting-for-approval",
  changeDefaultSettings: "change-default-settings",
  sendToLendflowUI: "send-to-lendflow-ui",
  completeOnboarding: "complete-onboarding",
  checkLendflowEligibility: "check-lendflow-eligibility",
  sendCardsForApprovedUsers: "send-cards-for-approved-users",
  increaseLimitforApprovedUsers: "increase-limit-for-approved-users",
  addCreditforApprovedUsers: "add-credit-for-approved-users",
  sendCardsForClosedUsers: "send-cards-for-closed-users",
  increaseLimitforClosedUsers: "increase-limit-for-closed-users",
  addCreditforClosedUsers: "add-credit-for-closed-users",
  logEveryoneOut: "log-everyone-out",
  viewPersonal: "view-personal-details",

  //Not used anymore
  prefill: "prefill",
  changeSettings: "change-settings",
};

export const FUELCARD_CUSTOMER_SCOPES = {
  // pages
  dashboard: "dashboard",
  cards: "cards",
  transactions: "transactions",
  fleet: "fleet",
  statements: "statements",
  paymentHistory: "paymentHistory",
  paymentMethods: "paymentMethods",
  accounting: "accounting",
  reports: "reports",
  elds: "elds",
  referAndEarn: "referAndEarn",
  accountSettings: "accountSettings",
  multipleUsers: "multipleUsers",
  // components
  bankAccounts: "bankAccounts",
  currentBillingCycleUsage: "currentBillingCycleUsage",
  accountDetails: "accountDetails",
  autoReload: "autoReload",
  repayment: "repayment",
  transactionsForStandardAndLargeFleetUsers:
    "transactionsForStandardAndLargeFleetUsers",
  transactionsForPrepaidUsers: "transactionsForPrepaidUsers",
  depositForStandardAndLargeFleetUsers: "depositForStandardAndLargeFleetUsers",
  depositForPrepaidUsers: "depositForPrepaidUsers",
  smsSecurity: "smsSecurity",
  fuelTypeSecurity: "fuelTypeSecurity",
  tankCapacitySecurity: "tankCapacitySecurity",
  telematicsSecurity: "telematicsSecurity",
  suspiciousFuelingSecurity: "suspiciousFuelingSecurity",
  requireJobID: "requireJobID",
  odometerSecurity: "odometerSecurity",
  inviteDriver: "inviteDriver",
  weeklyReport: "weeklyReport",
  dailyReport: "dailyReport",
};

export const FUELCARD_PERMISSIONS = {
  ["CREDIT_PLUS"]: [
    FUELCARD_CUSTOMER_SCOPES.elds,
    FUELCARD_CUSTOMER_SCOPES.smsSecurity,
    FUELCARD_CUSTOMER_SCOPES.multipleUsers,
    FUELCARD_CUSTOMER_SCOPES.inviteDriver,
    FUELCARD_CUSTOMER_SCOPES.fuelTypeSecurity,
    FUELCARD_CUSTOMER_SCOPES.tankCapacitySecurity,
    FUELCARD_CUSTOMER_SCOPES.telematicsSecurity,
    FUELCARD_CUSTOMER_SCOPES.odometerSecurity,
    FUELCARD_CUSTOMER_SCOPES.requireJobID,
    FUELCARD_CUSTOMER_SCOPES.suspiciousFuelingSecurity,
    FUELCARD_CUSTOMER_SCOPES.weeklyReport,
    FUELCARD_CUSTOMER_SCOPES.dailyReport,
  ],
  ["CREDIT_ENTERPRISE"]: [
    FUELCARD_CUSTOMER_SCOPES.elds,
    FUELCARD_CUSTOMER_SCOPES.smsSecurity,
    FUELCARD_CUSTOMER_SCOPES.multipleUsers,
    FUELCARD_CUSTOMER_SCOPES.inviteDriver,
    FUELCARD_CUSTOMER_SCOPES.fuelTypeSecurity,
    FUELCARD_CUSTOMER_SCOPES.tankCapacitySecurity,
    FUELCARD_CUSTOMER_SCOPES.telematicsSecurity,
    FUELCARD_CUSTOMER_SCOPES.odometerSecurity,
    FUELCARD_CUSTOMER_SCOPES.requireJobID,
    FUELCARD_CUSTOMER_SCOPES.suspiciousFuelingSecurity,
    FUELCARD_CUSTOMER_SCOPES.weeklyReport,
    FUELCARD_CUSTOMER_SCOPES.dailyReport,
  ],
  ["PREPAID_PLUS"]: [
    FUELCARD_CUSTOMER_SCOPES.elds,
    FUELCARD_CUSTOMER_SCOPES.smsSecurity,
    FUELCARD_CUSTOMER_SCOPES.multipleUsers,
    FUELCARD_CUSTOMER_SCOPES.inviteDriver,
    FUELCARD_CUSTOMER_SCOPES.fuelTypeSecurity,
    FUELCARD_CUSTOMER_SCOPES.tankCapacitySecurity,
    FUELCARD_CUSTOMER_SCOPES.telematicsSecurity,
    FUELCARD_CUSTOMER_SCOPES.suspiciousFuelingSecurity,
    FUELCARD_CUSTOMER_SCOPES.odometerSecurity,
    FUELCARD_CUSTOMER_SCOPES.requireJobID,
    FUELCARD_CUSTOMER_SCOPES.weeklyReport,
    FUELCARD_CUSTOMER_SCOPES.dailyReport,
  ],
  ["PREPAID_ENTERPRISE"]: [
    FUELCARD_CUSTOMER_SCOPES.elds,
    FUELCARD_CUSTOMER_SCOPES.smsSecurity,
    FUELCARD_CUSTOMER_SCOPES.multipleUsers,
    FUELCARD_CUSTOMER_SCOPES.inviteDriver,
    FUELCARD_CUSTOMER_SCOPES.fuelTypeSecurity,
    FUELCARD_CUSTOMER_SCOPES.tankCapacitySecurity,
    FUELCARD_CUSTOMER_SCOPES.telematicsSecurity,
    FUELCARD_CUSTOMER_SCOPES.odometerSecurity,
    FUELCARD_CUSTOMER_SCOPES.requireJobID,
    FUELCARD_CUSTOMER_SCOPES.suspiciousFuelingSecurity,
    FUELCARD_CUSTOMER_SCOPES.weeklyReport,
    FUELCARD_CUSTOMER_SCOPES.dailyReport,
  ],
  ["CREDIT"]: [] as string[],
  ["PREPAID"]: [] as string[],
  ["LARGE_FLEETS"]: [] as string[],
};

export const PLAID_REAUTHORIZE_REQUIRED = [
  "ITEM_LOGIN_REQUIRED",
  "PENDING_EXPIRATION",
];

export const ACH_ATTEMPT = {
  attempted: "",
  notRequired: "ACH not required",
  reauthRequired: "Plaid Re-authentication Required",
  changeRequired: "Changing Plaid Connection Required",
  noPlaidConnection: "No Plaid Connected to Account",
};

// Only add the resolvers here if they need to be validated
export const ADMIN_RESOLVERS = {
  // DASHBOARD
  adminDashboard: "adminDashboard",
  systemHealthCheck: "systemHealthCheck",
  graphOfCardholders: "graphOfCardholders",
  graphOfTransactions: "graphOfTransactions",

  // APPLICATIONS / APPLICATIONS_PENDING_DEPOSIT / APPROVED_CUSTOMERS
  userApplicants: "userApplicants",
  adminDefaultSetting: "adminDefaultSetting",
  adminsignUpBonus: "adminsignUpBonus",
  adminfuelDiscount: "adminfuelDiscount",

  // CUSTOMER_INFORMATION
  userDetails: "userDetails",
  reversedAuthorization: "reversedAuthorization",
  getUserCardActivityLogs: "getUserCardActivityLogs",
  cardHolderMemberShipStatus: "cardHolderMemberShipStatus",
  getUserWalletItems: "getUserWalletItems",

  // PAYMENTS
  pendingPaymentList: "pendingPaymentList",
  manualPaymentList: "manualPaymentList",

  // GLOBAL_SETTINGS
  // adminGlobalSettings: "adminGlobalSettings",
  updateAdminCardSetting: "updateAdminCardSetting",
  updateFuelDiscountAndBonus: "updateFuelDiscountAndBonus",

  // NOTIFICATIONS
  readAdminOutboundEmails: "readAdminOutboundEmails",

  // BLOGS
  readAdminBlogs: "readAdminBlogs",

  createAndUpdateAdminBlog: "createAndUpdateAdminBlog",
  deleteAdminBlogs: "deleteAdminBlogs",

  // RISKS
  readAdminUnderwritingSetting: "readAdminUnderwritingSetting",
  businessUnderwritingStatistics: "businessUnderwritingStatistics",
  listofOutstandingBalance: "listofOutstandingBalance",
  exportVerifiedUserWithHighRisk: "exportVerifiedUserWithHighRisk",

  // COLLECTIONS
  getDelinquencyData: "getDelinquencyData",
  getCollections: "getCollections",

  // SCRIPTS
  exportUsersData: "exportUsersData",
  exportTransactionsData: "exportTransactionsData",

  // DATA_ANALYSIS
  dataAnalysisUsers: "dataAnalysisUsers",

  // ACTIVITY_LOGS
  adminActivityLogs: "adminActivityLogs",
  userActivityLogs: "userActivityLogs",
  adminDownloadActivityLogs: "adminDownloadActivityLogs",

  // TEAM
  readAdmins: "readAdmins",

  adminSignup: "adminSignup",

  // ACCOUNT_SETTINGS
  updateAdminPassword: "updateAdminPassword",
  logoutAllUsers: "logoutAllUsers",
};

// This is for the backend
export const ADMIN_PAGE_RESOLVER_PERMISSIONS = {
  ["DASHBOARD"]: [
    ADMIN_RESOLVERS.adminDashboard,
    ADMIN_RESOLVERS.systemHealthCheck,
    ADMIN_RESOLVERS.graphOfCardholders,
    ADMIN_RESOLVERS.graphOfTransactions,
  ],
  ["APPLICATIONS"]: [
    ADMIN_RESOLVERS.userApplicants,
    ADMIN_RESOLVERS.adminDefaultSetting,
    ADMIN_RESOLVERS.adminsignUpBonus,
    ADMIN_RESOLVERS.adminfuelDiscount,
  ],
  ["APPLICATIONS_PENDING_DEPOSIT"]: [ADMIN_RESOLVERS.userApplicants],
  ["APPROVED_CUSTOMERS"]: [ADMIN_RESOLVERS.userApplicants],
  ["CUSTOMER_INFORMATION"]: [
    ADMIN_RESOLVERS.userDetails,
    ADMIN_RESOLVERS.reversedAuthorization,
    ADMIN_RESOLVERS.getUserCardActivityLogs,
    ADMIN_RESOLVERS.cardHolderMemberShipStatus,
    ADMIN_RESOLVERS.getUserWalletItems,
  ],
  ["PAYMENTS"]: [
    ADMIN_RESOLVERS.pendingPaymentList,
    ADMIN_RESOLVERS.manualPaymentList,
  ],
  ["GLOBAL_SETTINGS"]: [
    ADMIN_RESOLVERS.updateAdminCardSetting,
    ADMIN_RESOLVERS.updateFuelDiscountAndBonus,
  ],
  ["NOTIFICATIONS"]: [ADMIN_RESOLVERS.readAdminOutboundEmails],
  ["BLOGS"]: [
    ADMIN_RESOLVERS.readAdminBlogs,
    ADMIN_RESOLVERS.createAndUpdateAdminBlog,
    ADMIN_RESOLVERS.deleteAdminBlogs,
  ],
  ["RISKS"]: [
    ADMIN_RESOLVERS.readAdminUnderwritingSetting,
    ADMIN_RESOLVERS.businessUnderwritingStatistics,
    ADMIN_RESOLVERS.listofOutstandingBalance,
    ADMIN_RESOLVERS.exportVerifiedUserWithHighRisk,
  ],
  ["COLLECTIONS"]: [
    ADMIN_RESOLVERS.readAdmins,
    ADMIN_RESOLVERS.getDelinquencyData,
    ADMIN_RESOLVERS.readAdminOutboundEmails,
    ADMIN_RESOLVERS.getCollections,
  ],
  ["SCRIPTS"]: [
    ADMIN_RESOLVERS.exportUsersData,
    ADMIN_RESOLVERS.exportTransactionsData,
  ],
  ["DATA_ANALYSIS"]: [
    ADMIN_RESOLVERS.readAdmins,
    ADMIN_RESOLVERS.dataAnalysisUsers,
  ],
  ["ACTIVITY_LOGS"]: [
    ADMIN_RESOLVERS.adminActivityLogs,
    ADMIN_RESOLVERS.userActivityLogs,
    ADMIN_RESOLVERS.adminDownloadActivityLogs,
  ],
  ["TEAM"]: [ADMIN_RESOLVERS.readAdmins, ADMIN_RESOLVERS.adminSignup],
  ["ACCOUNT_SETTINGS"]: [
    ADMIN_RESOLVERS.updateAdminPassword,
    ADMIN_RESOLVERS.logoutAllUsers,
  ],
};

export const ADMIN_PERMISSIONS = {
  [ENUM_ADMIN_ROLES.SUPERADMIN]: [
    //pages
    ADMIN_SCOPES.dashboard,
    ADMIN_SCOPES.applications,
    ADMIN_SCOPES.applicationsPendingDeposit,
    ADMIN_SCOPES.approvedCustomers,
    ADMIN_SCOPES.customerInformation,
    ADMIN_SCOPES.payments,
    ADMIN_SCOPES.globalSettings,
    ADMIN_SCOPES.notifications,
    ADMIN_SCOPES.blogs,
    ADMIN_SCOPES.risks,
    ADMIN_SCOPES.collections,
    ADMIN_SCOPES.scripts,
    ADMIN_SCOPES.dataAnalysis,
    ADMIN_SCOPES.activityLogs,
    ADMIN_SCOPES.team,
    ADMIN_SCOPES.accountSettings,

    // COMMENTING THESE AS WE REMOVED FRONT-END PROCESS LEVEL PERMISSIONS RIGHT NOW
    //processes
    // ADMIN_SCOPES.deleteApplications,
    // ADMIN_SCOPES.editApplications,
    // ADMIN_SCOPES.changeDefaultSettings,
    // ADMIN_SCOPES.sendToLendflowUI,
    // ADMIN_SCOPES.changeSettings,
    // ADMIN_SCOPES.moveToApprove,
    // ADMIN_SCOPES.moveToDecline,
    // ADMIN_SCOPES.movetoWaitingForApproval,
    // ADMIN_SCOPES.completeOnboarding,
    // ADMIN_SCOPES.checkLendflowEligibility,
    // ADMIN_SCOPES.sendToLendflowUI,
    // ADMIN_SCOPES.sendCardsForApprovedUsers,
    // ADMIN_SCOPES.increaseLimitforApprovedUsers,
    // ADMIN_SCOPES.addCreditforApprovedUsers,
    // ADMIN_SCOPES.sendCardsForClosedUsers,
    // ADMIN_SCOPES.increaseLimitforClosedUsers,
    // ADMIN_SCOPES.addCreditforClosedUsers,
    // ADMIN_SCOPES.logEveryoneOut,
  ],
  [ENUM_ADMIN_ROLES.SALES]: [
    //pages
    ADMIN_SCOPES.applications,
    ADMIN_SCOPES.applicationsPendingDeposit,
    ADMIN_SCOPES.accountSettings,
    //processes
    ADMIN_SCOPES.editApplications,
    ADMIN_SCOPES.completeOnboarding,
  ],
  [ENUM_ADMIN_ROLES.CUSTOMER_SUPPORT]: [
    //pages
    ADMIN_SCOPES.applications,
    ADMIN_SCOPES.applicationsPendingDeposit,
    ADMIN_SCOPES.customerInformation,
    ADMIN_SCOPES.payments,
    ADMIN_SCOPES.accountSettings,
    ADMIN_SCOPES.dataAnalysis,
    //processes
    ADMIN_SCOPES.editApplications,
    ADMIN_SCOPES.completeOnboarding,
    ADMIN_SCOPES.sendCardsForApprovedUsers,
    ADMIN_SCOPES.increaseLimitforApprovedUsers,
    ADMIN_SCOPES.addCreditforApprovedUsers,
    ADMIN_SCOPES.applicationsPendingDeposit,
  ],
  [ENUM_ADMIN_ROLES.UNDERWRITER]: [
    //pages
    ADMIN_SCOPES.applications,
    ADMIN_SCOPES.approvedCustomers,
    ADMIN_SCOPES.risks,
    ADMIN_SCOPES.accountSettings,
    ADMIN_SCOPES.activityLogs,
    //processes
    ADMIN_SCOPES.moveToApprove,
    ADMIN_SCOPES.moveToDecline,
    ADMIN_SCOPES.movetoWaitingForApproval,
    ADMIN_SCOPES.sendToLendflowUI,
  ],
  [ENUM_ADMIN_ROLES.COLLECTIONS]: [
    //pages
    ADMIN_SCOPES.collections,
    ADMIN_SCOPES.accountSettings,
  ],
  [ENUM_ADMIN_ROLES.OPERATIONS]: [
    //pages
    ADMIN_SCOPES.applications,
    ADMIN_SCOPES.applicationsPendingDeposit,
    ADMIN_SCOPES.customerInformation,
    ADMIN_SCOPES.payments,
    ADMIN_SCOPES.accountSettings,
    ADMIN_SCOPES.dataAnalysis,
    ADMIN_SCOPES.blogs,
    //processes
    ADMIN_SCOPES.editApplications,
    ADMIN_SCOPES.completeOnboarding,
    ADMIN_SCOPES.sendCardsForApprovedUsers,
    ADMIN_SCOPES.increaseLimitforApprovedUsers,
    ADMIN_SCOPES.addCreditforApprovedUsers,
    ADMIN_SCOPES.applicationsPendingDeposit,
  ],
};

export const BUSINESS_TYPE: { [key: string]: string } = {
  LLC: "LLC",
  C_CORP: "C Corp",
  S_CORP: "S Corp",
  NON_PROFIT_ORGANIZATION: "Non-profit Organization",
  SOLE_PROPRIETORSHIP: "Sole Proprietorship",
  LLP: "LLP",
  OTHER: "Other",
};

export const CUSTOMER_TYPE: { [key: string]: string } = {
  CREDIT: "Credit",
  CREDIT_PLUS: "Credit+",
  CREDIT_ENTERPRISE: "Credit Enterprise",
  PREPAID: "Prepaid",
  PREPAID_PLUS: "Prepaid+",
  PREPAID_ENTERPRISE: "Prepaid Enterprise",
  LARGE_FLEETS: "Large Fleets",
};

export const COMPANY_TYPE: { [key: string]: string } = {
  REALESTATE: "Real Estate",
  RETAIL: "Retail",
  CONSULTING: "Consulting",
  INTERNET: "Internet",
  TRANSPORTATION: "Transportation",
  TRUCKING: "Trucking",
  CONSTRUCTION: "Construction",
  OTHER: "Other",
};

export const LANDING_SCORECARDS = [
  {
    id: 1,
    header: "savings",
    stats: "10%",
    statsDescription:
      "on average for fleet expenses after switching to RoadFlex",
    imageLink: ``, //`${NEXT_PUBLIC_CDN_URL}/landing_page/svg/white-truck-icon.svg`,
  },
  {
    id: 2,
    header: "hours",
    stats: "7",
    statsDescription: "saved per week on average on admin and finance work",
    imageLink: ``, // `${NEXT_PUBLIC_CDN_URL}/landing_page/svg/tick-icon.svg`,
  },
  {
    id: 3,
    header: "customer service",
    stats: "Top-notch",
    statsDescription:
      "Dedicated support tailored to your specific needs, when you need it",
    imageLink: ``, //`${NEXT_PUBLIC_CDN_URL}/landing_page/svg/hand-icon.svg`,
  },
];

export const LANDING_TESTIMONIALS = [
  {
    id: 1,
    review:
      "It's been great so far - RoadFlex is very easy to use and their customer service is great! They also help me build business credit score over time!",
    rated: 5,
    name: "Keep it Moving Hauling II",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/testimonials/images/first-page-testimonials.png`,
  },
  {
    id: 2,
    review:
      "I am grateful for RoadFlex. They offer great discounts and their cards work at all fuel stations. RoadFlex also helps us automate expense management and we are able to access the details of each transaction.",
    rated: 5,
    name: "Baker Nation Transports",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/testimonials/images/second-page-testimonials.png`,
  },
  {
    id: 3,
    review:
      "RoadFlex has been great! It is easy to use and I was able to quickly set up a card for each of my drivers and vehicles. I got approved for a high credit limit and can use my RoadFlex cards for all types of fleet expenses on top of fuel expenses.",
    rated: 5,
    name: "AKC Home Improvements",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/testimonials/images/third-page-testimonials.png`,
  },
  {
    id: 4,
    review:
      "RoadFlex helped me realize how much extra other fuel cards were charging me. I love the fact that I can save so much with RoadFlex by cutting extra fees, finding the cheapest gas stations and getting the highest gas discounts. Thank you RoadFlex!",
    rated: 5,
    name: "Ricky Trans LLC",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/testimonials/images/fourth-page-testimonials.png`,
  },
];
export const INDUSTRY_CONSTRUCTION_TESTIMONIALS = [
  {
    id: 1,
    review:
      "It's been great so far - RoadFlex is very easy to use and their customer service is great! They also help me build business credit score over time!",
    rated: 5,
    name: "Keep it Moving Hauling II",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/testimonials/images/first-page-testimonials.png`,
  },
];
export const INDUSTRY_FINAL_MILE_DELIVERY_TESTIMONIALS = [
  {
    id: 1,
    review:
      "It's been great so far - RoadFlex is very easy to use and their customer service is great! They also help me build business credit score over time!",
    rated: 5,
    name: "Keep it Moving Hauling II",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/testimonials/images/first-page-testimonials.png`,
  },
];
export const INDUSTRY_HVAC_TESTIMONIALS = [
  {
    id: 1,
    review:
      "It's been great so far - RoadFlex is very easy to use and their customer service is great! They also help me build business credit score over time!",
    rated: 5,
    name: "Keep it Moving Hauling II",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/testimonials/images/first-page-testimonials.png`,
  },
];
export const INDUSTRY_LANDSCAPING_TESTIMONIALS = [
  {
    id: 1,
    review:
      "It's been great so far - RoadFlex is very easy to use and their customer service is great! They also help me build business credit score over time!",
    rated: 5,
    name: "Keep it Moving Hauling II",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/testimonials/images/first-page-testimonials.png`,
  },
];
export const INDUSTRY_PASSENGER_TRANSPORT_TESTIMONIALS = [
  {
    id: 1,
    review:
      "It's been great so far - RoadFlex is very easy to use and their customer service is great! They also help me build business credit score over time!",
    rated: 5,
    name: "Keep it Moving Hauling II",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/testimonials/images/first-page-testimonials.png`,
  },
];
export const INDUSTRY_PEST_CONTROL_TESTIMONIALS = [
  {
    id: 1,
    review:
      "It's been great so far - RoadFlex is very easy to use and their customer service is great! They also help me build business credit score over time!",
    rated: 5,
    name: "Keep it Moving Hauling II",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/testimonials/images/first-page-testimonials.png`,
  },
];
export const INDUSTRY_TRUCKING_TESTIMONIALS = [
  {
    id: 1,
    review:
      "It's been great so far - RoadFlex is very easy to use and their customer service is great! They also help me build business credit score over time!",
    rated: 5,
    name: "Keep it Moving Hauling II",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/testimonials/images/first-page-testimonials.png`,
  },
];
export const INDUSTRY_UTILITIES_TESTIMONIALS = [
  {
    id: 1,
    review:
      "It's been great so far - RoadFlex is very easy to use and their customer service is great! They also help me build business credit score over time!",
    rated: 5,
    name: "Keep it Moving Hauling II",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/testimonials/images/first-page-testimonials.png`,
  },
];
export const INDUSTRY_WASTE_MANAGEMENT_TESTIMONIALS = [
  {
    id: 1,
    review:
      "It's been great so far - RoadFlex is very easy to use and their customer service is great! They also help me build business credit score over time!",
    rated: 5,
    name: "Keep it Moving Hauling II",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/testimonials/images/first-page-testimonials.png`,
  },
];
export const MAIN_FAQS = [
  {
    question: "How much money can I save with a fuel card?",
    answer:
      "Save at least 2\u00A2-3\u00A2 per gallon depending on your fuel card product.",
    bulletpoint: [],
    end: "",
  },
  {
    question: "How can a fuel card improve my credit score?",
    answer:
      "Unlike other fuel cards, RoadFlex fuel cards help build credit. We report monthly to credit bureaus to help you build business credit scores.",
    bulletpoint: [],
    end: "",
  },
  {
    question: "What can I purchase with a fuel card?",
    answer:
      "Our fuel card offers uncapped discounts and rewards on fuel purchases. You can also use your RoadFlex fuel card for all vehicle-related expenses such as maintenance, repairs, tires, tolls, insurance and more!",
    bulletpoint: [],
    end: "",
  },
  {
    question: "What are the advantages of a fuel card?",
    answer:
      "RoadFlex fuel card is designed to safeguard your business. With a modern fuel card, you can do the following:",
    bulletpoint: [
      "Pay for fuel",
      "Approve expenses in real time",
      "Control spending for individual vehicles or the entire fleet",
      "Avoid having to use cash, checks, or personal credit cards",
      "Add a layer of security to your spending",
    ],
    end: "While some fuel cards can only be used at certain gas stations only, RoadFlex can be used at all gas stations and truck stops that accept VISA. ",
  },
  {
    question: "Where are fuel cards accepted?",
    answer:
      "Our RoadFlex Fuel Card can be used for vehicle-related expenses at any location where Visa is accepted, providing coverage for the majority of fuel stations across the country.",
    bulletpoint: [],
    end: "",
  },
  {
    question:
      "Can I assign individual fuel purchase limits for each driver in my fleet?",
    answer:
      "Absolutely! You can leverage the powerful features and capabilities of RoadFlex fuel cards to:",
    bulletpoint: [
      "Enable your drivers to make purchases without having to use cash, checks, or a personal credit card",
      "Control purchasing by driver and limit transactions by amount, frequency or day. You can also limit their transactions to specific types of merchants",
      "See digital transaction details per line-item for each purchase, such as the grade of fuel and the number of gallons",
      "Monitor, manage, and control costs associated with the vehicles in the fleet, either by driver or by vehicle",
    ],
    end: "",
  },
  {
    question: "Do I need a business credit score to sign up/join RoadFlex?",
    answer:
      "No, all you need to submit is your EIN, driver's license, SSN, and past three months proof of business revenue.",
    bulletpoint: [],
    end: "",
  },
  {
    question: "How can a fuel card help improve fleet management?",
    answer:
      "You can leverage the capabilities of a fuel card to coordinate, plan and direct vehicles, drivers and other crew members.",
    bulletpoint: [],
    end: "",
  },
  {
    question:
      "How does the RoadFlex Fuel Card platform compare to other fuel cards?",
    answer:
      "RoadFlex offers advanced software and expense management platform to provide modern control and visibility that many other fuel card programs do not offer. Additionally, RoadFlex fuel cards can be used anywhere that accepts Visa, giving your drivers absolute convenience. RoadFlex does not charge any hidden fees and offers transparent pricing, and is devoted to help fleets cut costs and increase profits.",
    bulletpoint: [],
    end: "",
  },
];

export const AFFILIATE_PROGRAM_FAQS = [
  {
    question: "How does the RoadFlex Referral program work?",
    answer:
      "The RoadFlex Referral program is designed to equip you as an affiliate with the information you'd need to sell a fleet business on RoadFlex's fuel card product. Should that business sign up for RoadFlex through your custom affiliate link, we'll pay you directly based on the size of that business.",
    bulletpoint: [] as string[],
    end: "",
  },
  {
    question: "When will I receive my payment?",
    answer:
      "When you refer a customer to RoadFlex and they become a customer, we'll issue your payment as soon as they purchase 4,000 gallons of fuel with their RoadFlex cards. If the referred customer has 9 vehicles or fewer, they will not qualify for a referral payout. If the referred customer does not get approved for an RoadFlex Fuel Card and/or they do not purchase 4,000 gallons of fuel with their RoadFlex cards, they will not qualify for a referral payout.",
    bulletpoint: [],
    end: "",
  },
  {
    question: "How will I receive my payment?",
    answer:
      "We'll issue your payment through PayPal as soon as the referred customer purchases 4,000 gallons of fuel with their RoadFlex cards.",
    bulletpoint: [],
    end: "",
  },
  {
    question: "Can anyone become an RoadFlex Affiliate?",
    answer:
      "The best affiliates are individuals who work at companies that serve businesses with fleets of vehicles. Companies that provide telematics services, fleet management software, or vehicle dealerships, to name a few examples, would make great RoadFlex affiliates considering they work directly with companies that could use RoadFlex's fuel card.",
    bulletpoint: [],
    end: "",
  },
  {
    question: "Once I have my custom link, how do I refer someone?",
    answer:
      "Simply send your custom link to the decision maker at the company you are referring and tell them to sign up using the form. If the decision maker would prefer to speak to a member of the RoadFlex team, simply email sales@roadflex.com and we'll be happy to make that connection.",
    bulletpoint: [],
    end: "",
  },
];

export const PARTNERSHIP_FAQS = [
  {
    question: "How much money can I save with a fuel card?",
    answer:
      "Save at least 2\u00A2-3\u00A2 per gallon depending on your fuel card product.",
    bulletpoint: [],
    end: "",
  },
  {
    question: "How can a fuel card improve my credit score?",
    answer:
      "Unlike other fuel cards, RoadFlex fuel cards help build credit. We report monthly to credit bureaus to help you build business credit scores.",
    bulletpoint: [],
    end: "",
  },
  {
    question: "What can I purchase with a fuel card?",
    answer:
      "Our fuel card offers uncapped discounts and rewards on fuel purchases. You can also use your RoadFlex fuel card for all vehicle-related expenses such as maintenance, repairs, tires, tolls, insurance and more!",
    bulletpoint: [],
    end: "",
  },
  {
    question: "What are the advantages of a fuel card?",
    answer:
      "RoadFlex fuel card is designed to safeguard your business. With a modern fuel card, you can do the following:",
    bulletpoint: [
      "Pay for fuel",
      "Approve expenses in real time",
      "Control spending for individual vehicles or the entire fleet",
      "Avoid having to use cash, checks, or personal credit cards",
      "Add a layer of security to your spending",
    ],
    end: "While some fuel cards can only be used at certain gas stations only, RoadFlex can be used at all gas stations and truck stops that accept VISA. ",
  },
  {
    question: "Where are fuel cards accepted?",
    answer:
      "Our RoadFlex Fuel Card can be used for vehicle-related expenses at any location where Visa is accepted, providing coverage for the majority of fuel stations across the country.",
    bulletpoint: [],
    end: "",
  },
  {
    question:
      "Can I assign individual fuel purchase limits for each driver in my fleet?",
    answer:
      "Absolutely! You can leverage the powerful features and capabilities of RoadFlex fuel cards to:",
    bulletpoint: [
      "Enable your drivers to make purchases without having to use cash, checks, or a personal credit card",
      "Control purchasing by driver and limit transactions by amount, frequency or day. You can also limit their transactions to specific types of merchants",
      "See digital transaction details per line-item for each purchase, such as the grade of fuel and the number of gallons",
      "Monitor, manage, and control costs associated with the vehicles in the fleet, either by driver or by vehicle",
    ],
    end: "",
  },
  {
    question: "Do I need a business credit score to sign up/join RoadFlex?",
    answer:
      "No, all you need to submit is your EIN, driver's license, SSN, and past three months proof of business revenue.",
    bulletpoint: [],
    end: "",
  },
  {
    question: "How can a fuel card help improve fleet management?",
    answer:
      "You can leverage the capabilities of a fuel card to coordinate, plan and direct vehicles, drivers and other crew members.",
    bulletpoint: [],
    end: "",
  },
  {
    question:
      "How does the RoadFlex Fuel Card platform compare to other fuel cards?",
    answer:
      "RoadFlex offers advanced software and expense management platform to provide modern control and visibility that many other fuel card programs do not offer. Additionally, RoadFlex fuel cards can be used anywhere that accepts Visa, giving your drivers absolute convenience. RoadFlex does not charge any hidden fees and offers transparent pricing, and is devoted to help fleets cut costs and increase profits.",
    bulletpoint: [],
    end: "",
  },
];

export const ROADFLEX_ADVANTAGES = [
  {
    heading: "Visa card accepted everywhere",
    text1:
      "Use your RoadFlex card anywhere Visa is accepted, for all fleet related expenses. Prevent card misuse and fraud with our modern card controls.",
    text2: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/landing_page/svg/absolute-icon.svg`,
  },
  {
    heading: "Spend Less, Save More",
    text1:
      "Leverage RoadFlex's competitive discounts nationwide with advanced software to cut costs and eliminate fraud.",
    text2: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/landing_page/svg/spend-less-icon.svg`,
  },
  {
    heading: "Control every dollar spent",
    text1:
      "See where every dollar is being spent on. Set rules and spending limits for each card or driver, issue cards instantly and  authorize payments in real-time.",
    text2: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/landing_page/svg/full-control-icon.svg`,
  },
];
export const ROADFLEX_FLEETCARD_ADVANTAGES = [
  {
    heading: "Competitive Discounts",
    text1:
      "Enjoy competitive discounts at all fuel stations and truck stops. Find the lowest fuel prices using the RoadFlex Driver App.",
    text2: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/fleet_card_page/svg/competitive-discount-icon.svg`,
  },
  {
    heading: "Accepted Nationwide",
    text1:
      "Use our cards anywhere Visa is accepted. Never worry about out-of-network fuel stations or fees again.",
    text2: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/fleet_card_page/svg/accepted-nationwide-icon.svg`,
  },
  {
    heading: "Pay For Other Fleet Expenses",
    text1:
      "Enable fuel-only purchases or allow your drivers to spend on fleet expenses such as maintenance, tires or parking.",
    text2: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/fleet_card_page/svg/pay-for-other-fleet-icon.svg`,
  },
  {
    heading: "Control every dollar spent",
    text1:
      "Set rules and spending limits for each card or driver, issue cards instantly and authorize payments in real-time.",
    text2: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/fleet_card_page/svg/control-every-dollar-icon.svg`,
  },
  {
    heading: "Reporting & Tracking",
    text1:
      "Get full visibility into every dollar spent using RoadFlex spend management platform. See all digital transaction details by card.",
    text2: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/fleet_card_page/svg/report-tracking-icon.svg`,
  },
  {
    heading: "Prevent Card Misuse & Fraud",
    text1:
      "Leverage RoadFlex SMS Security with Telematics to validate legitimate spending and receive real-time alerts to block fraud in its tracks.",
    text2: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/fleet_card_page/svg/prevent-card-misuse-icon.svg`,
  },
];
export const ROADFLEX_PARNTERS_FLEETUP_ADVANTAGE = [
  {
    heading: "Accepted anywhere",
    text1:
      "Accepted anywhere that accepts Visa. 5\u00A2 rebates per gallon at all gas stations, 1% cashback for all non-fuel expenses.",
    text2: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/partners/images/accepted-anywhere.png`,
  },
  {
    heading: "$0 hidden fees",
    text1:
      "Save up to $3,350 per vehicle annually. Stop paying for hidden fees such as out-of-network and transaction fees.",
    text2: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/partners/images/no-hidden-fees.png`,
  },
  {
    heading: "Easy-to-use Card controls",
    text1:
      "Set rules and spending limits for each card or driver, issue cards instantly and authorize payments in real-time.",
    text2: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/partners/images/card-controls.png`,
  },
];
export const ROADFLEX_FLEETUP_ADVANTAGES = [
  {
    heading: "Prevent fuel card misuse and fuel theft",
    text1:
      "For every purchase, RoadFlex validates the driver, the fuel card and the vehicle, therefore reducing any spending that's not related to a work vehicle or for any fleet-related expenses.",
    bulletpoint: [],
    imageLink: `${NEXT_PUBLIC_CDN_URL}/security-features/images/telematics-connection.png`,
  },
  {
    heading: "Set spending limits and controls",
    text1:
      "Set rules and spending limits for each card or driver, issue cards instantly and set day and time controls for transactions and specify approved merchants. See where every dollar is being spent and authorize payments in real-time.",
    bulletpoint: [],
    imageLink: `${NEXT_PUBLIC_CDN_URL}/controls/images/automate-payment-approvals.png`,
  },
  {
    heading: "See where each dollar is spent on",
    text1:
      "Track where and when your vehicles and cards are being used. Get alerted on suspicious transactions and lock the cards in a few seconds.",
    bulletpoint: [],
    imageLink: `${NEXT_PUBLIC_CDN_URL}/fleet_card_page/images/dollar-spent-on.png`,
  },
  {
    heading: "Receive digital receipts for every purchase",
    text1:
      "Automatically receive detailed digital records of all your transactions. Collect memos and pictures of your team's receipts in seconds, directly from their phones.",
    bulletpoint: [],
    imageLink: `${NEXT_PUBLIC_CDN_URL}/controls/images/digital-receipts-for-every-purchase.png`,
  },
  {
    heading: "Automatically sync up with FleetUp",
    text1:
      "Combine telematics data with your payments activity to leverage next-generation analytics, reporting and fraud prevention. Connect seamlessly with FleetUp with a few clicks.",
    bulletpoint: [],
    imageLink: `${NEXT_PUBLIC_CDN_URL}/partners/images/fleetup-eld-integration.png`,
  },
  {
    heading: "Dedicated Customer Success",
    text1:
      "Your dedicated RoadFlex Customer Success Team will ensure your issues are addressed immediately. Connect with us 24/7 via text, email, or phone!",
    bulletpoint: [],
    imageLink: `${NEXT_PUBLIC_CDN_URL}/landing_page/images/expense_management_new/dedicated-customer-success.png`,
  },
];
export const ROADFLEX_PROMILES_ADVANTAGES = [
  {
    heading: "Universal Fuel Access & Data Management",
    text1: "",
    bulletpoint: [
      "Manage all your fueling data on one dashboard, for both in-yard and out-of-yard fueling activity.",
      "Reduce fuel spend by optimizing your fueling activity. Use a fuel card accepted universally that offers competitive discounts.",
      "Seamlessly combine with telematics for advanced reporting, analytics and fraud prevention. Get a 360 degree view of your fuel efficiency data for each of your drivers and vehicles.",
      "Fuel purchases are seamlessly integrated into ProMiles Fuel Tax.",
    ],
    imageLink: `${NEXT_PUBLIC_CDN_URL}/partners/images/promiles/universal-fuel-access.png`,
  },
  {
    heading: "Fuel Risk Management",
    text1: "",
    bulletpoint: [
      "Modern proactive fuel risk management platform that automates expense management, fuel reporting and fuel analysis.",
      "Set rules and spending limits for each card or driver, issue cards instantly and authorize payments in real-time.",
      "Advanced security features to prevent fuel theft / fraud by verifying cardholder, transaction location and vehicle location in real-time at the time of each purchase.",
      "Additional card protection via ProMiles Mobile Endpoint Security.",
    ],
    imageLink: `${NEXT_PUBLIC_CDN_URL}/partners/images/promiles/fuel-risk-management.png`,
  },
  {
    heading: "Automated Fuel Tax",
    text1: "",
    bulletpoint: [
      "Fuel tax reporting system that uses fuel purchase data and ELD / GPS data to automatically generate IFTA, Mile Taxes, IRP percentage calculations and MPG reports.",
      "Automate tax reporting process by generating necessary reports. Eliminate manual reporting and admin tasks.",
      "Simplified Compliance - Ensure all trip records and reports are in compliance with regulations and tax laws.",
    ],
    imageLink: `${NEXT_PUBLIC_CDN_URL}/partners/images/promiles/automated-fuel-tax.png`,
  },
  {
    heading: "Route Optimization",
    text1: "",
    bulletpoint: [
      "Plan accurate truck-specific routes with directions, distance, duration, and actual fuel costs along the route.",
      "Ensure load profitability by reducing out-of-route miles and out-of-network purchases while managing compliance.",
      "Enhanced driver experience with real-time routing, including visibility to retail and negotiated fuel discounts, DEF pricing, Ex-Tax price for 12,500+ truck accessible fueling locations.",
    ],
    imageLink: `${NEXT_PUBLIC_CDN_URL}/partners/images/promiles/route-optimization.png`,
  },
  {
    heading: "Credentials Management",
    text1: "",
    bulletpoint: [
      "Manage and digitize all fleet credentials for driver, truck, trailer, and the company.",
      "Eliminate manual paper process and documentation with a back-office system as the single source of truth.",
      "Real time delivery, updates, and visibility to most recent credentials via Driver App",
    ],
    imageLink: `${NEXT_PUBLIC_CDN_URL}/partners/images/promiles/credentials-management.png`,
  },
  {
    heading: "Driver Compliance",
    text1: "",
    bulletpoint: [
      "Automated national permitting application and management solution for carriers to efficiently order jurisdictional OS/OW and trip & fuel permits.",
      "Expedite roadside process, mitigate violations, penalties and downtime.",
      "Automate in-cab delivery of current and required trip documents, including IFTA, IRP, permits, cab cards, vehicle inspections, certifications and more.",
    ],
    imageLink: `${NEXT_PUBLIC_CDN_URL}/partners/images/promiles/driver-compliance.png`,
  },
];
export const ROADFLEX_LINXUP_ADVANTAGES = [
  {
    heading: "Prevent fuel card misuse and fuel theft",
    text1:
      "For every purchase, RoadFlex validates the driver, the fuel card and the vehicle, therefore reducing any spending that’s not related to a work vehicle or for any fleet-related expenses.",
    bulletpoint: [],
    imageLink: `${NEXT_PUBLIC_CDN_URL}/security-features/images/telematics-connection.png`,
  },
  {
    heading: "Set spending limits and controls",
    text1:
      "Set rules and spending limits for each card or driver, issue cards instantly and set day and time controls for transactions and specify approved merchants. See where every dollar is being spent and authorize payments in real-time.",
    bulletpoint: [],
    imageLink: `${NEXT_PUBLIC_CDN_URL}/controls/images/automate-payment-approvals.png`,
  },
  {
    heading: "See where each dollar is spent on",
    text1:
      "Track where and when your vehicles and cards are being used. Get alerted on suspicious transactions and lock the cards in a few seconds.",
    bulletpoint: [],
    imageLink: `${NEXT_PUBLIC_CDN_URL}/fleet_card_page/images/dollar-spent-on.png`,
  },
  {
    heading: "Receive digital receipts for every purchase",
    text1:
      "Automatically receive detailed digital records of all your transactions. Collect memos and pictures of your team's receipts in seconds, directly from their phones.",
    bulletpoint: [],
    imageLink: `${NEXT_PUBLIC_CDN_URL}/controls/images/digital-receipts-for-every-purchase.png`,
  },
  {
    heading: "Automatically sync up with Linxup",
    text1:
      "Combine telematics data with your payments activity to leverage next-generation analytics, reporting and fraud prevention. Connect seamlessly with Linxup with a few clicks.",
    bulletpoint: [],
    imageLink: `${NEXT_PUBLIC_CDN_URL}/partners/images/linxup/linxup-eld-integration.png`,
  },
  {
    heading: "Dedicated Customer Success",
    text1:
      "Your dedicated RoadFlex Customer Success Team will ensure your issues are addressed immediately. Connect with us 24/7 via text, email, or phone!",
    bulletpoint: [],
    imageLink: `${NEXT_PUBLIC_CDN_URL}/landing_page/images/expense_management_new/dedicated-customer-success.png`,
  },
];
export const ROADFLEX_PARNTERS_APPLICATION = [
  {
    heading: "Easy 3-min application",
    text1:
      "Our application process is easy with no personal credit check or personal guarantee.",
    text2: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/partners/images/step-1-yellow.png`,
  },
  {
    heading: "Order cards and set spending policies",
    text1: "Create different rules and card controls for different employees.",
    text2: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/partners/images/step-2-yellow.png`,
  },
  {
    heading: "Start saving by spending with your RoadFlex cards",
    text1:
      "Receive competitive discounts and rebates for all fuel, fleet, field and business related expenses!",
    text2: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/partners/images/step-3-yellow.png`,
  },
];
export const ROADFLEX_ADVANTAGE_FEATURE_ONE = [
  {
    heading:
      "Leverage your Advantage One connected card to eliminate fuel theft and fraud",
    text1:
      "Sync up with your vehicle's location and fuel tank level in real-time to verify that purchases are legitimate and does not exceed the vehicle's fuel tank capacity. Automatically flag and decline suspicious transactions before they happen.",
    bulletpoint: [],
    imageLink: `${NEXT_PUBLIC_CDN_URL}/security-features/images/telematics-connection.png`,
  },
  {
    heading: "Set spending limits and controls",
    text1:
      "Set rules and spending limits for each card or driver, issue cards instantly and set day and time controls for transactions and specify approved merchants. See where every dollar is being spent and authorize payments in real-time.",
    bulletpoint: [],
    imageLink: `${NEXT_PUBLIC_CDN_URL}/controls/images/automate-payment-approvals.png`,
  },
];
export const ROADFLEX_ADVANTAGE_FEATURE_TWO = [
  {
    heading: "",
    bulletpoint: [
      "Combine telematics data with your payments activity to leverage next-generation analytics, reporting and fraud prevention.",
      "With automated odometer and fuel gauge capture, you'll know exactly where and when your drivers are making purchases.",
      "Block suspicious fuel transactions happening away from your vehicles and import your fuel transactions to your telematics solution for better fuel efficiency insights.",
    ],
    imageLink: `${NEXT_PUBLIC_CDN_URL}/partners/images/geotab-eld-integration.png`,
  },
];
export const ROADFLEX_FLEETUP_ONE = [
  {
    heading:
      "Leverage your Advantage One connected card to eliminate fuel theft and fraud",
    text1:
      "Sync up with your vehicle's location and fuel tank level in real-time to verify that purchases are legitimate and does not exceed the vehicle's fuel tank capacity. Automatically flag and decline suspicious transactions before they happen.",
    bulletpoint: [],
    imageLink: `${NEXT_PUBLIC_CDN_URL}/security-features/images/telematics-connection.png`,
  },
  {
    heading: "Set spending limits and controls",
    text1:
      "Set rules and spending limits for each card or driver, issue cards instantly and set day and time controls for transactions and specify approved merchants. See where every dollar is being spent and authorize payments in real-time.",
    bulletpoint: [],
    imageLink: `${NEXT_PUBLIC_CDN_URL}/controls/images/automate-payment-approvals.png`,
  },
];
export const ROADFLEX_TECHSBOOK_ADVANTAGES = [
  {
    heading: "Schedule",
    text1:
      "Effortlessly book an appointment with a certified technician in just minutes!",
    text2: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/partners/images/schedule.png`,
  },
  {
    heading: "Monitor",
    text1:
      "Stay updated with real-time tracking of your technician's progress.",
    text2: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/partners/images/monitor.png`,
  },
  {
    heading: "Rate",
    text1:
      "Select technicians based on reliable ratings and contribute your own reviews to guide others.",
    text2: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/partners/images/rate.png`,
  },
];
export const ROADFLEX_PARNTERS_TECHSBOOK_APPLICATION = [
  {
    heading: "Define Your Job",
    text1:
      "Specify what Job you want the technician to perform, whether it's a dash cam, GPS, ELD, or any other installation and also how many devices you need the technician to install.",
    text2: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/partners/images/step-1-orange.png`,
  },
  {
    heading: "Choose Your Technician",
    text1:
      "Choose which technician you like the most by seeing the available technicians in your area, their availability, their rating, and other information you can have thanks to Techsbook.",
    text2: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/partners/images/step-2-orange.png`,
  },
  {
    heading: "Use You RoadFlex Card As Your Payment Method",
    text1: "",
    text2: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/partners/images/step-3-orange.png`,
  },
];
export const ROADFLEX_PARTNER_BENEFITS = [
  {
    heading: "Dedicated Customer Success & Onboarding",
    text1:
      "You will receive a dedicated team to guarantee your success throughout your RoadFlex journey. Your team will help you set up your entire dashboard and customize spending policies and limits for your entire fleet (by driver or vehicle).",
    text2: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/partners/svg/dedicated-customer-success.svg`,
  },
  {
    heading: "Admin and Manager Training",
    text1:
      "We'll make sure your fleet managers and bookkeepers are equipped to access the benefits of RoadFlex through our personalized onboarding and training. Additionally, we can also customize new levels of user access if you need it.",
    text2: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/partners/svg/admin-manage-training.svg`,
  },
  {
    heading: "Program Implementation",
    text1:
      "All of your drivers / employees will receive a package to their domiciles with their respective physical fuel cards. The package will include relevant documentation to ensure the program is successful (for launch, onboarding and servicing).",
    text2: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/partners/svg/program-implementation.svg`,
  },
  {
    heading: "Quarterly Review & Reporting",
    text1:
      "Your Customer Success Manager will go over your fleet's performance data on a quarterly basis so that your fleet is continuously improving. This will uncover existing metrics and areas of improvement for you can continuously drive ROI on your RoadFlex solution.",
    text2: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/partners/svg/reviews-report.svg`,
  },
];
export const ROADFLEX_PREPAID_ADVANTAGES = [
  {
    heading: "Competitive Discounts",
    text1:
      "Enjoy competitive discounts at all fuel stations and truck stops. Find the lowest fuel prices using the RoadFlex Driver App.",
    text2: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/fleet_card_page/svg/competitive-discount-icon.svg`,
  },
  {
    heading: "Accepted Nationwide",
    text1:
      "Use our cards anywhere Visa is accepted. Never worry about out-of-network fuel stations or fees again.",
    text2: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/fleet_card_page/svg/accepted-nationwide-icon.svg`,
  },
  {
    heading: "Pay For Other Fleet Expenses",
    text1:
      "Enable fuel-only purchases or allow your drivers to spend on fleet expenses such as maintenance, tires or parking.",
    text2: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/fleet_card_page/svg/pay-for-other-fleet-icon.svg`,
  },
  {
    heading: "Control every dollar spent",
    text1:
      "Set rules and spending limits for each card or driver, issue cards instantly and authorize payments in real-time.",
    text2: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/fleet_card_page/svg/control-every-dollar-icon.svg`,
  },
  {
    heading: "Reporting & Tracking",
    text1:
      "Get full visibility into every dollar spent using RoadFlex spend management platform. See all digital transaction details by card.",
    text2: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/fleet_card_page/svg/report-tracking-icon.svg`,
  },
  {
    heading: "Prevent Card Misuse & Fraud",
    text1:
      "Leverage the RoadFlex SMS Unlock feature and Telematics integration to validate legitimate  spending in real time. Get alerted for suspicious transactions and block them.",
    text2: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/fleet_card_page/svg/prevent-card-misuse-icon.svg`,
  },
];

export const ROADFLEX_FLEETCARD_COMPARISON_UNLIMITED = [
  {
    heading: "",
    text1: "No spending limits",
    text2: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/fleet_card_page/svg/tick-icon.svg`,
  },
  {
    heading: "",
    text1: "Build business credit",
    text2: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/fleet_card_page/svg/tick-icon.svg`,
  },
  {
    heading: "",
    text1: "Get approved same day regardless of credit score",
    text2: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/fleet_card_page/svg/tick-icon.svg`,
  },
  {
    heading: "",
    text1:
      "Can be used for all fleet and business related expenses anywhere that accepts Visa",
    text2: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/fleet_card_page/svg/tick-icon.svg`,
  },
  {
    heading: "",
    text1:
      "3\u00A2 per gallon of unlimited discounts at all fuel stations and truck stops nationwide",
    text2: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/fleet_card_page/svg/tick-icon.svg`,
  },
];

export const ROADFLEX_FLEETCARD_COMPARISON_PRESTIGE = [
  {
    heading: "",
    text1: "Purchase fuel anywhere that accepts Visa",
    text2: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/fleet_card_page/svg/tick-icon.svg`,
  },
  {
    heading: "",
    text1:
      "Customize spend limits and card controls for each vehicle and driver",
    text2: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/fleet_card_page/svg/tick-icon.svg`,
  },
  {
    heading: "",
    text1:
      "Eliminate fuel theft and card fraud with advanced security features",
    text2: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/fleet_card_page/svg/tick-icon.svg`,
  },
  {
    heading: "",
    text1: "Centralized reporting, visibility into every dollar spend",
    text2: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/fleet_card_page/svg/tick-icon.svg`,
  },
  {
    heading: "",
    text1:
      "2\u00A2 per gallon of unlimited discounts at all fuel stations and truck stops nationwide",
    text2: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/fleet_card_page/svg/tick-icon.svg`,
  },
];

export const ROADFLEX_ADVANTAGEONE_BENEFITS = [
  {
    heading: "Save Thousands Yearly",
    text1: "Reduce fuel spend by 11% annually",
    text2: "Fuel, fleet and employee expenses all on one card",
    text3: "Get discounts and cashback for all fuel, fleet and field expenses",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/common/icons/map-point.svg`,
  },
  {
    heading: "Leave Hidden Fees behind",
    text1: "Save up to $3,350 per vehicle annually",
    text2:
      "Stop paying for hidden fees such as out-of-network and transaction fees",
    text3: "Get clear visibility on the fees you pay",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/common/icons/dollar-person.svg`,
  },
  {
    heading: "Stop Fuel Fraud in its tracks",
    text1:
      "Monitor suspicious activity with real-time alerts, activity reports, and transaction blocking",
    text2:
      "Verify that vehicles are within 100 meters of a fuel location with vehicle tracking",
    text3:
      "Track fuel level and capacity to ensure transactions do not exceed tank capacity",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/common/icons/card-ok.svg`,
  },
];

export const ROADFLEX_PREPAID_PROFIT = [
  {
    heading: "Short and Easy Application",
    text1: "Get approved without a credit score or financial statements",
    text2: "No personal credit check",
    text3: "No personal guarantee required",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/common/icons/card-ok.svg`,
  },
  {
    heading: "Fair and Transparent Pricing",
    text1: "Get full visibility on the fees that you pay",
    text2:
      "Other major fuel cards have fees such as out-of-network, transaction and late fees",
    text3: "Our customers save at least $1,700 monthly on random fees",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/common/icons/dollar-person.svg`,
  },
  {
    heading: "Save Thousands Every Year",
    text1:
      "Competitive Discounts at all fuel stations and truck stops nationwide - over 140,000 locations",
    text2: "Eliminate card misuse, fuel fraud and fuel theft",
    text3: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/common/icons/map-point.svg`,
  },
];
export const ROADFLEX_REFERRAL_PROGRAM = [
  {
    heading: "Sign up to be an RoadFlex affiliate",
    text1:
      "Once you sign up to be an affiliate, we'll create a custom link for you to send to your connections that could be potential RoadFlex customers. Your connections can sign up and apply for the RoadFlex Fuel Card directly through your custom link.",
    text2: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/common/icons/card-ok.svg`,
  },
  {
    heading: "Send your link to businesses with fleets of vehicles",
    text1:
      "We'll track everyone that applies for RoadFlex through your custom link. When someone becomes a customer and purchases 4,000 gallons of fuel using their RoadFlex cards, we'll pay you the amount based on the tier size of the referred customer.",
    text2: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/common/icons/dollar-person.svg`,
  },
  {
    heading: "Earn based on the size of your referral",
    text1:
      "When a customer you've referred becomes a customer of RoadFlex, you'll earn up to $1,000 just for referring the customer to RoadFlex. The amount of the payout will depend on the size of the fleet business and payments will only be made after a customer has been approved for the RoadFlex Fuel Card and has purchased 4,000 gallons using their RoadFlex cards. If a referred business has fewer than 10 vehicles, there will not be a payout.",
    text2: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/common/icons/map-point.svg`,
  },
];
export const ROADFLEX_PREPAID_FEATURES = [
  {
    heading: "Build business credit score",
    text1:
      "RoadFlex reports monthly to Experian - every purchase you make with RoadFlex Unlimited will help you build business credit.",
    text2: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/svg/dollar-docs.svg`,
  },
  {
    heading: "See where every dollar is spent",
    text1:
      "Get full visibility into every dollar that you and your team spend on, including details for each specific transaction.",
    text2: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/svg/dollar-bills.svg`,
  },
  {
    heading: "Leverage huge savings on fuel and more",
    text1:
      "The RoadFlex Unlimited card gives you access to discounts at over 45,000 gas stations and truck stops nationwide.",
    text2: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/svg/fuel-tank.svg`,
  },
];

export const PREPAID_FLEET_INDUSTRIES = [
  {
    id: 1,
    name: "Construction",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/common/revolver/images/construction.png`,
    text1:
      "Simplifying expenses for specific construction projects, crews, job sites and client groups",
    text2: "",
  },
  {
    id: 2,
    name: "Oil & Gas",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/common/revolver/images/oil-gas.png`,
    text1: "Leverage fuel management to limit costs and optimize vehicle use",
    text2: "",
  },
  {
    id: 3,
    name: "Utilities",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/common/revolver/images/utility-services.png`,
    text1:
      "Giving utilities a unified fleet dashboard for real-time visibility across their fleet operations",
    text2: "",
  },
  {
    id: 4,
    name: "Food & Beverage",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/common/revolver/images/food-beverage.png`,
    text1:
      "Delivering goods on-time, with enhanced visibility, greater fleet insights and maximized productivity",
    text2: "",
  },
  {
    id: 5,
    name: "EVs & Renewables",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/common/revolver/images/ev-charge.png`,
    text1:
      "Purchase renewable fuels and recharge EVs. Supporting progress on electrification and fleet modernization",
    text2: "",
  },
  {
    id: 6,
    name: "Last Mile Delivery",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/common/revolver/images/last-mile-delivery.png`,
    text1:
      "Optimizing daily deliveries with faster routing, cheaper fuel stops and increased mileage",
    text2: "",
  },
  {
    id: 7,
    name: "Trucking",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/common/revolver/images/trucking-logistics.png`,
    text1:
      "Use RoadFlex for a smart fuel card accepted nationwide that allows you to purchase everything fleet-related",
    text2: "",
  },
  {
    id: 8,
    name: "Field Services",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/common/revolver/images/field-services.png`,
    text1:
      "Empowering field service providers and technicians to maximize profits and improve operational efficiency",
    text2: "",
  },
  {
    id: 9,
    name: "Government Services",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/common/revolver/images/emergency-services.png`,
    text1:
      "Helping government agencies across the US leverage real-time data to extract greater value from their fleets",
    text2: "",
  },
  {
    id: 10,
    name: "Passenger Transit",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/common/revolver/images/passenger-transit.png`,
    text1:
      "Helping fleets increase pickup efficiency and passenger safety to maximize productivity",
    text2: "",
  },
];

export const VETERANS_BAGDE = [
  {
    imageLink: `${NEXT_PUBLIC_CDN_URL}/veterans/images/airforce.png`,
  },
  {
    imageLink: `${NEXT_PUBLIC_CDN_URL}/veterans/images/navy.png`,
  },
  {
    imageLink: `${NEXT_PUBLIC_CDN_URL}/veterans/images/marine.png`,
  },
  {
    imageLink: `${NEXT_PUBLIC_CDN_URL}/veterans/images/army.png`,
  },
  {
    imageLink: `${NEXT_PUBLIC_CDN_URL}/veterans/images/guard.png`,
  },
];

export const VETERANS_ADVANTAGES = [
  {
    heading: "Quick Veteran Approval Process",
    text1:
      "Our application process is easy with no business or personal credit checks. Submit your DD214 or Veteran stamped Drivers License with your application.",
    text2: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/veterans/images/quick-approval-process.png`,
  },
  {
    heading: "No restrictions or limitations",
    text1:
      "Regardless of your fleet size, your monthly subscription fees will be waived for as long as you are a client.",
    text2: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/veterans/images/no-restrictions-limitations.png`,
  },
  {
    heading: "Live support, 7 days a week",
    text1:
      "In addition to robust self-serve options, experienced RoadFlex Fleet Advisors are here to help you 7 days a week.",
    text2: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/veterans/images/live-support-everyday.png`,
  },
];

export const PARTNERSHIP_ADVANTAGES = [
  {
    heading: "Increase Your Revenue",
    text1:
      "Get additional revenue by offering your customers a best-in-class business card designed for fleets",
    text2: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/common/icons/fuel-card.svg`,
  },
  {
    heading: "Increase Customer Retention",
    text1:
      "Bundle your solution with a modern Visa business card, increasing loyalty and overall customer satisfaction",
    text2: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/common/icons/dollar-palm.svg`,
  },
  {
    heading: "1-click Integrations",
    text1:
      "Make the partnership easy with seamless integrations with the tools your customer uses",
    text2: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/common/icons/fuel-pump.svg`,
  },
];

export const PARTNERSHIP_OTHER_SIDE_ADVANTAGES = [
  {
    heading: "Referral Partners",
    text1:
      "Our success is your success. We look for partners with which we can cross refer businesses to, while earning commission",
    text2: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/common/icons/referral.svg`,
  },
  {
    heading: "Integration Partners",
    text1:
      "We are always keen to expand our connected ecosystem to help better support our mutual customers",
    text2: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/common/icons/integration-partners.svg`,
  },
  {
    heading: "Reseller Partners",
    text1:
      "RoadFlex provides the training and support you need to implement our solution as a strategic offering",
    text2: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/common/icons/reseller-partner.svg`,
  },
];

export const ECOSYSTEM_LISTING = [
  {
    title: "Linxup",
    summary:
      "Leading provider of vehicle and asset-tracking solutions for fleets and service companies. Linxup delivers powerful but easy-to-use GPS solutions through a software-as-a-service platform.",
    pathLink: "/partners/linxup",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/ecosystem/images/linxup.jpg`,
  },
  {
    title: "Fleetup",
    summary:
      "FleetUp connects every asset, employee, and worksite. Customers achieve operational excellence through greater productivity, increased safety and security.",
    pathLink: "/partners/fleetup",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/ecosystem/images/fleetup.jpg`,
  },
  {
    title: "Techsbook",
    summary:
      "Techsbook offers expertise in technician certification, providing high-quality technicians, empowering customers to self-book installation services within minutes, and offering comprehensive project insights.",
    pathLink: "/partners/techsbook",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/ecosystem/images/techsbook.jpg`,
  },
  {
    title: "ProMiles",
    summary:
      "ProMiles assists companies and drivers in planning legal trips that are safer and more efficient. Through its software and partnership with RoadFlex, it helps cut the largest expense for trucking companies - fuel costs.",
    pathLink: "/partners/promiles",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/ecosystem/images/promiles.jpg`,
  },
  {
    title: "Samsara",
    summary:
      "Pioneer of the Connected Operations Cloud, which enables organizations that depend on physical operations to harness Internet of Things (IoT) data to develop actionable insights and improve their operations.",
    pathLink: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/ecosystem/images/samsara.jpg`,
  },
  {
    title: "Fleetio",
    summary:
      "Fleetio is a comprehensive software platform that helps fleets of all sizes track, analyze and improve fleet operations.",
    pathLink: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/ecosystem/images/fleetio.jpg`,
  },
  {
    title: "Geotab",
    summary:
      "A leader in connected vehicle and asset solutions, leverages advanced data analytics and AI to enhance fleet performance, safety, and sustainability while optimizing costs.",
    pathLink: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/ecosystem/images/geotab.jpg`,
  },
  {
    title: "Verizon Connect",
    summary:
      "Their full suite of industry-defining solutions and services puts innovation, automation and connected data to work for customers and helps them be safer, more efficient and more productive.",
    pathLink: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/ecosystem/images/verizon-connect.jpg`,
  },
  // {
  //   title: "Quickbooks",
  //   summary:
  //     "Businesses use QuickBooks to automate processes such as invoicing, payments, accounting, and more. These features make it easier to manage and track business finances.",
  //   pathLink: "",
  //   imageLink: `${NEXT_PUBLIC_CDN_URL}/ecosystem/images/quickbooks.jpg`,
  // },
  {
    title: "Azuga",
    summary:
      "Azuga provides reliable end-to-end solutions through leading hardware, integrated driver rewards, award-winning fleet applications, coaching, and more.",
    pathLink: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/ecosystem/images/azuga.jpg`,
  },
];
export const PROMILES_LISTING = [
  {
    title: "Fleet View",
    summary:
      "View all of your fleet related details regarding compliance, fueling, and expenses in one dashboard.",
  },
  {
    title: "Compliance Hub",
    summary:
      "Single source for fleets to manage all their routing, compliance, credentials, fuel tax, fuel, fuel surcharges, etc.",
  },
  {
    title: "Fuel Risk Hub",
    summary:
      "Modern proactive fuel risk management platform that automates expense management, fuel reporting and fuel analysis.",
  },
  {
    title: "Fuel Tax Hub",
    summary:
      "Fuel tax reporting system that uses fuel purchase data to automatically generate IFTA, Mile Taxes, IRP percentage calculations and MPG reports.",
  },
  {
    title: "Fuel Access Hub",
    summary:
      "Gasoline, diesel, hybrid and EV efficiency and usage insights. Streamline reporting, analytics and fuel consumption benchmarking.",
  },
  {
    title: "Driver App",
    summary:
      "Solutions designed to optimize fuel purchases, routing, and compliance requirements associated with credentials.",
  },
  {
    title: "Credentials",
    summary:
      "Eliminate manual paper process and documentation with a back-office system as the single source of truth. Manage and digitize all fleet credentials.",
  },
  {
    title: "Driver Compliance",
    summary:
      "Real-time visibility to all credentials including driver, vehicle, trailer, company, and custom credentials, and the ability to present to roadside or shippers.",
  },
  {
    title: "Route Optimization",
    summary:
      "Ensure load profitability by reducing out-of-route miles and out-of-network purchases while managing compliance.",
  },
];

export const FLEET_COMPANIES = [
  {
    id: 1,
    name: "Midwest",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/landing_page/images/fleet_companies/midwest.png`,
    width: 326,
    height: 86,
  },
  {
    id: 2,
    name: "RTP",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/landing_page/images/fleet_companies/rtp.png`,
    width: 118,
    height: 61.01,
  },
  {
    id: 3,
    name: "Baylin",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/landing_page/images/fleet_companies/baylin.png`,
    width: 153,
    height: 42.76,
  },
  {
    id: 4,
    name: "JNS Haulers",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/landing_page/images/fleet_companies/jns.png`,
    width: 120,
    height: 128,
  },
  {
    id: 5,
    name: "Ready Swift",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/landing_page/images/fleet_companies/ready-swift.png`,
    width: 199,
    height: 35.2,
  },
  {
    id: 6,
    name: "Artful Authority",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/landing_page/images/fleet_companies/artful.png`,
    width: 120,
    height: 62.78,
  },
  {
    id: 7,
    name: "Fleetify",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/landing_page/images/fleet_companies/fleetify.png`,
    width: 130,
    height: 33.01,
  },
  {
    id: 8,
    name: "Drive haul",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/landing_page/images/fleet_companies/drive-haul.png`,
    width: 111,
    height: 75.92,
  },
  {
    id: 9,
    name: "Send it transport",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/landing_page/images/fleet_companies/send-it-trans.png`,
    width: 204,
    height: 40.67,
  },
];

export const TESTIMONIALS = [
  {
    imageSrc: `${NEXT_PUBLIC_CDN_URL}/case_study/images/aus-case-study-image.png`,
    testimonial:
      "Last year we had our vehicle broken into and our fuel cards stolen. I was able to immediately get a hold of RoadFlex. Within minutes, they took care of everything - turned off our cards instantly and issued virtual cards to ensure our operations continued without interruption.",
    author: "MORGAN THOMAS",
    company: "Appalachian Utility Services",
    caseStudyUrl: `customer-stories/appalachian-utility-services`,
    videoSrc: `${NEXT_PUBLIC_CDN_URL}/case_study/videos/au-services-testimonial-short.mp4`,
    videoSrcImage: `${NEXT_PUBLIC_CDN_URL}/case_study/images/aus-testimonial-video-thumbnail.png`,
  },
  {
    imageSrc: `${NEXT_PUBLIC_CDN_URL}/case_study/images/cvs-case-study-image.png`,
    testimonial:
      "We had our vehicles broken into and most of our cards stolen. We called RoadFlex, and within a minute they turned off the old cards and assigned our drivers new ones, ready to use. RoadFlex’s customer support is top notch and their product is very easy to use.",
    author: "JOSEPH HERNANDEZ",
    company: "Central Valley Services",
    caseStudyUrl: "#",
    videoSrc: "",
    videoSrcImage: "",
  },
  {
    imageSrc: `${NEXT_PUBLIC_CDN_URL}/case_study/images/r6l-case-study-image.png`,
    testimonial:
      "We previously had trouble keep track of all of our expenses, and knowing what exactly each driver purchased on a specific day. With RoadFlex, we can see each transaction in real-time, while having granular details on what they purchased: number of gallons, price per gallon, type of fuel, etc.",
    author: "MICHAEL THOMPSON",
    company: "Route 6 Logistics Inc.",
    caseStudyUrl: "#",
    videoSrc: "",
    videoSrcImage: "",
  },
  {
    imageSrc: `${NEXT_PUBLIC_CDN_URL}/case_study/images/aus-case-study-image.png`,
    testimonial:
      "With RoadFlex we have peace of mind: we can deactivate and active cards within seconds, and reassign cards to new drivers. On top of this, we never have to worry about fuel theft or fraud again. The card spending controls and automated blocking of suspicious transactions helped us reduce fuel spend considerably in the first year.",
    author: "MARK MORALES",
    company: "JNS Haulers Corp.",
    caseStudyUrl: "#",
    videoSrc: "",
    videoSrcImage: "",
  },
  {
    imageSrc: ``,
    testimonial:
      "We've been using RoadFlex for a year, and it has made a big difference. The discounts at the pump are great, and I appreciate the ease of use. No more keeping track of paper receipts - everything is digital and organized. Excellent service and a must-have.",
    author: "JONATHAN ANDERSEN",
    company: "NSR Logistics",
    caseStudyUrl: "#",
    videoSrc: "",
    videoSrcImage: "",
  },
  {
    imageSrc: ``,
    testimonial:
      "RoadFlex has exceeded my expectations. The process to sign up was quick and easy, and the benefits are clear. The fuel savings are real, and managing my expenses has never been simpler.",
    author: "JAMES RODRIGUEZ",
    company: "ProAir HVAC",
    caseStudyUrl: "#",
    videoSrc: "",
    videoSrcImage: "",
  },
  {
    imageSrc: ``,
    testimonial:
      "Whenever we've had questions or needed support, the RoadFlex team has been prompt and helpful. The virtual cards have been very useful - we created and assigned a new virtual card in just seconds, and our employee was able to use it instantly.",
    author: "ETHAN CARLSON",
    company: "PrimeField Services",
    caseStudyUrl: "#",
    videoSrc: "",
    videoSrcImage: "",
  },
  {
    imageSrc: ``,
    testimonial:
      "I've been using the RoadFlex fuel card for seven months now, and it has made managing our fuel expenses so much easier. The card is accepted at all fuel stations, which is incredibly convenient. The reporting features help us keep track of our spending accurately.",
    author: "ABIGAIL PETERSON",
    company: "Pinnacle Construction",
    caseStudyUrl: "#",
    videoSrc: "",
    videoSrcImage: "",
  },
];

export const CUSTOMER_SUCCESS = [
  {
    id: 1,
    header: "Save an average of",
    stats: "11%",
    statsDescription: "in annual spending when switching to RoadFlex",
    imageLink: ``,
  },
  {
    id: 2,
    header: "US-based",
    stats: "24 / 7 / 365",
    statsDescription: "phone, email and text support",
    imageLink: ``,
  },
  {
    id: 3,
    header: "Over",
    stats: "5100+",
    statsDescription: "happy customers rely on RoadFlex every day",
    imageLink: ``,
  },
];

export const BROKER_ADVANTAGES = [
  {
    heading: "Increase carrier use and expand carrier network",
    text1:
      "RoadFlex modernizes fleet payments, expenses and rewards programs, allowing brokers to better engage with their customer base",
    text2: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/broker/svg/carrier-use.svg`,
  },
  {
    heading: "Improve customer relationships",
    text1:
      "Help your customers save time and money by providing additional value add.",
    text2: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/broker/svg/customer-relations.svg`,
  },
  {
    heading: "Leverage cutting-edge payments technology",
    text1:
      "Replace outdated payment methods to increase efficiency, customer satisfaction and profit margins.",
    text2: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/broker/svg/technology.svg`,
  },
  {
    heading: "Increase carrier loyalty",
    text1:
      "Our flexible solutions enable brokers to offer new services to carriers, increase retention and promote loyalty.",
    text2: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/broker/svg/carrier-loyalty.svg`,
  },
  {
    heading: "Get personalized, 100% US-based customer service",
    text1:
      "You can count on personalized service any and every time you reach out whether that's by phone, text, or email.",
    text2: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/broker/svg/customer-service.svg`,
  },
  {
    heading: "Explore ways to leverage data solutions",
    text1:
      "Our flexible solutions enable brokers to leverage data to increase retention and promote loyalty by refining load pricing.",
    text2: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/broker/svg/data.svg`,
  },
];

export const SUSTAINABILITY_ADVANTAGES = [
  {
    heading: "One card for all types of fueling needs",
    text1:
      "The RoadFlex card can be used anywhere Visa is accepted, and  is accepted at all fuel stations, truck stops and EV charging stations.",
    text2: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/sustainability/svg/card-tick.svg`,
  },
  {
    heading: "Seamless EV Charging reports nationwide",
    text1:
      "RoadFlex makes it incredibly easy to track transactions, create seamless reports and analyze your fleet's performance, all in one dashboard.",
    text2: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/sustainability/svg/visa.svg`,
  },
  {
    heading: "Integrate with all your tools to manage vehicles and drivers",
    text1:
      "RoadFlex offers a user-friendly software with telematics integrations and security features to meet all of your fleet's needs.",
    text2: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/sustainability/svg/poll-circle.svg`,
  },
  {
    heading: "Making the transition to sustainable energy easy",
    text1:
      "RoadFlex has solutions to mixed fleets. If you start to introduce EVs in your fleet, all of your drivers can keep using RoadFlex as normal.",
    text2: "",
    imageLink: `${NEXT_PUBLIC_CDN_URL}/sustainability/svg/energy-circle.svg`,
  },
];

export const CATEGORY_LABELS: { [key: string]: any } = {
  automated_fuel_dispensers: {
    label: "Fuel Station, at the pump (5542)",
    value: "automated_fuel_dispensers",
  },
  service_stations: {
    label: "Fuel Station, inside the store (5541)",
    value: "service_stations",
  },
  car_and_truck_dealers_used_only: {
    label: "Used-Truck dealership (5512)",
    value: "car_and_truck_dealers_used_only",
  },
  car_and_truck_dealers_new_used: {
    label: "New-Truck dealership (5511)",
    value: "car_and_truck_dealers_new_used",
  },
  auto_body_repair_shops: {
    label: "Repair Shops (7531 and 7538)",
    value: "auto_body_repair_shops",
  },
  automotive_tire_stores: {
    label: "Tire Stores (5532)",
    value: "automotive_tire_stores",
  },
  car_washes: {
    label: "Car Wash (7542)",
    value: "car_washes",
  },
  eating_places_restaurants: {
    label: "Eating Places, Restaurants (5812)",
    value: "eating_places_restaurants",
  },
  fuel_dealers_non_automotive: {
    label: "Fuel Dealers Non Automotive (5983)",
    value: "fuel_dealers_non_automotive",
  },
  grocery_stores_supermarkets: {
    label: "Grocery Stores, Supermarkets (5411)",
    value: "grocery_stores_supermarkets",
  },
  hardware_equipment_and_supplies: {
    label: "Hardware, Equipment, and Supplies (5072)",
    value: "hardware_equipment_and_supplies",
  },
  hotels_motels_and_resorts: {
    label: "Hotels, Motels, and Resorts (7011)",
    value: "hotels_motels_and_resorts",
  },
  insurance_default: {
    label: "Insurance - Default (6399)",
    value: "insurance_default",
  },
  insurance_underwriting_premiums: {
    label: "Insurance Underwriting, Premiums (6300)",
    value: "insurance_underwriting_premiums",
  },
  parking_lots_garages: {
    label: "Parking lots, Meters and Garages (7523)",
    value: "parking_lots_garages",
  },
  tire_retreading_and_repair: {
    label: "Tire Repair (7534)",
    value: "tire_retreading_and_repair",
  },
  truck_stop_iteration: {
    label: "Truck Stop (7511)",
    value: "truck_stop_iteration",
  },
  truck_utility_trailer_rentals: {
    label: "Truck/Trailer Rentals (7513)",
    value: "truck_utility_trailer_rentals",
  },
  u_s_federal_government_agencies_or_departments: {
    label: "U.S. Federal Government Agencies or Departments (9405)",
    value: "u_s_federal_government_agencies_or_departments",
  },
  motor_vehicle_supplies_and_new_parts: {
    label: "Motor Vehicle Supplies And New Parts (5013)",
    value: "motor_vehicle_supplies_and_new_parts",
  },
  auto_paint_shops: {
    label: "Auto Paint Shops (7535)",
    value: "auto_paint_shops",
  },
  automobile_associations: {
    label: "Automobile Associations (8675)",
    value: "automobile_associations",
  },
  automotive_parts_and_accessories_stores: {
    label: "Automotive Parts And Accessories Stores (5533)",
    value: "automotive_parts_and_accessories_stores",
  },
  cleaning_and_maintenance: {
    label: "Cleaning And Maintenance (7349)",
    value: "cleaning_and_maintenance",
  },
  petroleum_and_petroleum_products: {
    label: "Petroleum And Petroleum Products (5172)",
    value: "petroleum_and_petroleum_products",
  },
  tolls_bridge_fees: {
    label: "Tolls Bridge Fees (4784)",
    value: "tolls_bridge_fees",
  },
  towing_services: {
    label: "Towing Services (7549)",
    value: "towing_services",
  },
  car_rental_agencies: {
    label: "Car Rental Agencies (7512)",
    value: "car_rental_agencies",
  },
  commercial_equipment: {
    label: "Commercial Equipment (5046)",
    value: "commercial_equipment",
  },
  electric_vehicle_charging: {
    label: "Electric Vehicle Charging (5552)",
    value: "electric_vehicle_charging",
  },
  equipment_rental: {
    label: "Equipment Rental (7394)",
    value: "equipment_rental",
  },
  fast_food_restaurants: {
    label: "Fast Food Restaurants (5814)",
    value: "fast_food_restaurants",
  },
  government_services: {
    label: "Government Services (9399)",
    value: "government_services",
  },
  hardware_stores: {
    label: "Hardware Stores (5251)",
    value: "hardware_stores",
  },
  home_supply_warehouse_stores: {
    label: "Home Supply Warehouse Stores (5200)",
    value: "home_supply_warehouse_stores",
  },
  miscellaneous_auto_dealers: {
    label: "Miscellaneous Auto Dealers (5599)",
    value: "miscellaneous_auto_dealers",
  },
  miscellaneous_food_stores: {
    label:
      "Miscellaneous Food Stores - Convenience Stores and Specialty Markets (5499)",
    value: "miscellaneous_food_stores",
  },
  motor_freight_carriers_and_trucking: {
    label: "Motor Freight Carriers and Trucking (4214)",
    value: "motor_freight_carriers_and_trucking",
  },
  transportation_services: {
    label: "Transportation Services (4789)",
    value: "transportation_services",
  },
  wholesale_clubs: {
    label: "Wholesale Clubs (5300)",
    value: "wholesale_clubs",
  },
  miscellaneous_general_services: {
    label: "Miscellaneous General Services (7299)",
    value: "miscellaneous_general_services",
  },
  auto_and_home_supply_stores: {
    label: "Auto and Home Supply Stores (5531)",
    value: "auto_and_home_supply_stores",
  },
  miscellaneous_specialty_retail: {
    label: "Miscellaneous Specialty Retail (5999)",
    value: "miscellaneous_specialty_retail",
  },
  computer_software_stores: {
    label: "Computer Software Stores (5734)",
    value: "computer_software_stores",
  },
  industrial_supplies: {
    label: "Industrial Supplies (5085)",
    value: "industrial_supplies",
  },
  miscellaneous_repair_shops: {
    label: "Miscellaneous Repair Shops (7699)",
    value: "miscellaneous_repair_shops",
  },
  professional_services: {
    label: "Professional Services (8999)",
    value: "professional_services",
  },
  lumber_building_materials_stores: {
    label: "Lumber Building Materials Stores (5211)",
    value: "lumber_building_materials_stores",
  },
  taxicabs_limousines: {
    label: "Taxicabs/Limousines (4121)",
    value: "taxicabs_limousines",
  },
};

// we have removed "auto_service_shops" and have combined it with "auto_body_repair_shops" under the label "Repair Shops"
export const CATEGORIES_MASTER = [
  "auto_and_home_supply_stores",
  "auto_body_repair_shops",
  "auto_paint_shops",
  "automated_fuel_dispensers",
  "automobile_associations",
  "automotive_parts_and_accessories_stores",
  "automotive_tire_stores",
  "car_and_truck_dealers_new_used",
  "car_and_truck_dealers_used_only",
  "car_rental_agencies",
  "car_washes",
  "cleaning_and_maintenance",
  "commercial_equipment",
  "eating_places_restaurants",
  "electric_vehicle_charging",
  "equipment_rental",
  "fast_food_restaurants",
  "fuel_dealers_non_automotive",
  "government_services",
  "grocery_stores_supermarkets",
  "hardware_stores",
  "hardware_equipment_and_supplies",
  "home_supply_warehouse_stores",
  "hotels_motels_and_resorts",
  "insurance_default",
  "insurance_underwriting_premiums",
  "miscellaneous_auto_dealers",
  "miscellaneous_food_stores",
  "motor_freight_carriers_and_trucking",
  "motor_vehicle_supplies_and_new_parts",
  "parking_lots_garages",
  "petroleum_and_petroleum_products",
  "service_stations",
  "tire_retreading_and_repair",
  "tolls_bridge_fees",
  "towing_services",
  "transportation_services",
  "truck_stop_iteration",
  "truck_utility_trailer_rentals",
  "u_s_federal_government_agencies_or_departments",
  "wholesale_clubs",
  "miscellaneous_general_services",
].join(",");

export const CATEGORIES_MASTER_MAP = {
  auto_and_home_supply_stores: "BUSINESS",
  auto_body_repair_shops: "VEHICLE",
  auto_paint_shops: "VEHICLE",
  auto_service_shops: "VEHICLE",
  automated_fuel_dispensers: "FUEL",
  automobile_associations: "VEHICLE",
  automotive_parts_and_accessories_stores: "VEHICLE",
  automotive_tire_stores: "VEHICLE",
  car_and_truck_dealers_new_used: "VEHICLE",
  car_and_truck_dealers_used_only: "VEHICLE",
  car_rental_agencies: "BUSINESS",
  car_washes: "VEHICLE",
  cleaning_and_maintenance: "VEHICLE",
  commercial_equipment: "BUSINESS",
  eating_places_restaurants: "BUSINESS",
  electric_vehicle_charging: "FUEL",
  equipment_rental: "BUSINESS",
  fast_food_restaurants: "BUSINESS",
  fuel_dealers_non_automotive: "VEHICLE",
  government_services: "BUSINESS",
  grocery_stores_supermarkets: "BUSINESS",
  hardware_stores: "BUSINESS",
  hardware_equipment_and_supplies: "BUSINESS",
  home_supply_warehouse_stores: "BUSINESS",
  hotels_motels_and_resorts: "BUSINESS",
  insurance_default: "BUSINESS",
  insurance_underwriting_premiums: "BUSINESS",
  miscellaneous_auto_dealers: "BUSINESS",
  miscellaneous_food_stores: "BUSINESS",
  motor_freight_carriers_and_trucking: "BUSINESS",
  motor_vehicle_supplies_and_new_parts: "VEHICLE",
  parking_lots_garages: "VEHICLE",
  petroleum_and_petroleum_products: "VEHICLE",
  service_stations: "FUEL",
  tire_retreading_and_repair: "VEHICLE",
  tolls_bridge_fees: "VEHICLE",
  towing_services: "VEHICLE",
  transportation_services: "BUSINESS",
  truck_stop_iteration: "FUEL",
  truck_utility_trailer_rentals: "BUSINESS",
  u_s_federal_government_agencies_or_departments: "BUSINESS",
  wholesale_clubs: "BUSINESS",
  miscellaneous_general_services: "BUSINESS",
};

export const ADDITIONAL_MERCHANT_CATEGORIES = [
  {
    label: "Miscellaneous Specialty Retail (5999)",
    value: "miscellaneous_specialty_retail",
  },
  {
    label: "Computer Software Stores (5734)",
    value: "computer_software_stores",
  },
  {
    label: "Industrial Supplies (5085)",
    value: "industrial_supplies",
  },
  {
    label: "Miscellaneous Repair Shops (7699)",
    value: "miscellaneous_repair_shops",
  },
  {
    label: "Professional Services (8999)",
    value: "professional_services",
  },
  {
    label: "Lumber Building Materials Stores (5211)",
    value: "lumber_building_materials_stores",
  },
  {
    label: "Taxicabs/Limousines (4121)",
    value: "taxicabs_limousines",
  },
  {
    label: "Others",
    value: "others",
  },
];

export const TIMEZONES = {
  PST: "America/Los_Angeles",
  UTC: "UTC",
};

export const TIME_FORMATS = {
  a: "YYYY-MM-DD",
  b: "DD-MM-YYYY",
  c: "MM-DD-YYYY",
  d: "DD/MM/YYYY",
  e: "DD/MM/YYYY hh:mm:ss",
  f: "dd/MM/yyyy",
  g: "YYYY-MM-DD[T]HH:mm:ss",
  h: "mm/dd/yy",
  i: "YYYY-MM-DD HH:mm:ss",
  j: "YYYY-MM-DD[T]00:00:00:000Z",
  k: "YYYY-MM-DD[T]HH:mm:ss:000Z",
};

export const COMMON_GRAPH_CONFIG = {
  subtitle: {
    text: "Click and drag in the plot area to zoom in",
  },
  chart: {
    zoomType: "x",
    type: "column",
    borderRadius: 10,
  },
  xAxis: {
    type: "datetime",
    labels: {
      overflow: "justify",
    },
  },
  legend: {
    layout: "horizontal",
    align: "center",
    verticalAlign: "top",
    enabled: true,
  },
  plotOptions: {
    series: {
      showInNavigator: true,
    },
  },
  credits: {
    enabled: false,
  },
};

export const REPORTS_SCATTER_GRAPH_CONFIG = {
  subtitle: {
    text: "",
  },
  chart: {
    zoomType: "x",
    type: "scatter",
    borderRadius: 10,
  },
  xAxis: {
    type: "datetime",
    labels: {
      overflow: "justify",
    },
  },
  tooltip: {
    crosshairs: true,
    shared: true,
  },
  legend: {
    layout: "horizontal",
    align: "center",
    verticalAlign: "top",
    enabled: true,
  },
  plotOptions: {
    series: {
      showInNavigator: true,
    },
  },
  credits: {
    enabled: false,
  },
};

export const REPORTS_LINE_GRAPH_CONFIG = {
  subtitle: {
    text: "",
  },
  chart: {
    zoomType: "x",
    type: "",
    borderRadius: 10,
  },
  xAxis: {
    type: "datetime",
    labels: {
      overflow: "justify",
    },
  },
  tooltip: {
    crosshairs: true,
    shared: true,
  },
  legend: {
    layout: "horizontal",
    align: "center",
    verticalAlign: "top",
    enabled: true,
  },
  plotOptions: {
    series: {
      showInNavigator: true,
    },
  },
  credits: {
    enabled: false,
  },
};

export const REPORTS_BAR_GRAPH_CONFIG = {
  subtitle: {
    text: "",
  },
  chart: {
    zoomType: "x",
    type: "column",
    borderRadius: 10,
  },
  tooltip: {
    crosshairs: true,
    shared: true,
  },
  legend: {
    layout: "horizontal",
    align: "center",
    verticalAlign: "top",
    enabled: true,
  },
  plotOptions: {
    column: {
      showInNavigator: true,
      maxPointWidth: 20,
    },
  },
  credits: {
    enabled: false,
  },
};

export const REPORTS_PIE_GRAPH_CONFIG = {
  subtitle: {
    text: "",
  },
  chart: {
    zoomType: "x",
    type: "pie",
    borderRadius: 10,
  },
  tooltip: {
    crosshairs: true,
    shared: true,
  },
  legend: {
    layout: "horizontal",
    align: "center",
    verticalAlign: "top",
    enabled: true,
  },
  plotOptions: {
    column: {
      showInNavigator: true,
      maxPointWidth: 20,
    },
  },
  credits: {
    enabled: false,
  },
};

export const BAR_GRAPH_CONFIG = {
  subtitle: {
    text: "",
  },
  chart: {
    zoomType: "x",
    type: "column",
    borderRadius: 10,
  },
  xAxis: {
    type: "datetime",
    labels: {
      overflow: "justify",
    },
  },
  tooltip: {
    crosshairs: true,
    shared: true,
  },
  legend: {
    layout: "horizontal",
    align: "center",
    verticalAlign: "top",
    enabled: true,
  },
  plotOptions: {
    column: {
      showInNavigator: true,
      maxPointWidth: 20,
    },
  },
  credits: {
    enabled: false,
  },
};

export const DELETE_APPLICATIONS = "Delete Applications";

export const TRN_CREATED = "issuing.transaction";
export const AUTH_CREATED = "issuing.authorization";
export const ISSUE_AUTHRIZATION_REQUEST = "issuing_authorization.request";
export const TRN_STATUS_CAPTURE = "capture";
export const TRN_STATUS_REFUND = "refund";

export const FAILURE_TYPES = {
  plaid: "PLAID",
  stripe: "STRIPE",
  modernTreasury: "MODERN_TREASURY",
  custom: "CUSTOM",
};

export const ELD_SYSTEMS = {
  GEOTAB: "GEOTAB",
  SAMSARA: "SAMSARA",
  AZUGA: "AZUGA",
  MOTIVE: "MOTIVE",
  UTH: "UTH",
  FLEETUP: "FLEETUP",
  VERIZON: "VERIZON",
};

export enum ENUM_STRING_MATCH_TYPE {
  contains = "contains",
  notContains = "notContains",
  startsWith = "startsWith",
  endsWith = "endsWith",
  equals = "equals",
  notEquals = "notEquals",
}

export enum ENUM_DATE_MATCH_TYPE {
  dateIs = "dateIs",
  dateIsNot = "dateIsNot",
  dateBefore = "dateBefore",
  dateAfter = "dateAfter",
}

export enum ENUM_NUMBER_MATCH_TYPE {
  equals = "equals",
  notEquals = "notEquals",
  gt = "gt",
  lt = "lt",
  gte = "gte",
  lte = "lte",
}

export const DEVELOPER_EMAIL_IDS = [
  "abhinav.degala@roadflex.com",
  "alok.tanwar@roadflex.com",
  "rohan.jain@roadflex.com",
  "vivek.yadav@roadflex.com",
  "greg@roadflex.com",
];

export const GEOLOCATION_URL = "https://geolocation-db.com/json/";

export const OPERATIONAL_HOURS_TIMES = [
  "12:00 AM",
  "12:30 AM",
  "1:00 AM",
  "1:30 AM",
  "2:00 AM",
  "2:30 AM",
  "3:00 AM",
  "3:30 AM",
  "4:00 AM",
  "4:30 AM",
  "5:00 AM",
  "5:30 AM",
  "6:00 AM",
  "6:30 AM",
  "7:00 AM",
  "7:30 AM",
  "8:00 AM",
  "8:30 AM",
  "9:00 AM",
  "9:30 AM",
  "10:00 AM",
  "10:30 AM",
  "11:00 AM",
  "11:30 AM",
  "12:00 PM",
  "12:30 PM",
  "1:00 PM",
  "1:30 PM",
  "2:00 PM",
  "2:30 PM",
  "3:00 PM",
  "3:30 PM",
  "4:00 PM",
  "4:30 PM",
  "5:00 PM",
  "5:30 PM",
  "6:00 PM",
  "6:30 PM",
  "7:00 PM",
  "7:30 PM",
  "8:00 PM",
  "8:30 PM",
  "9:00 PM",
  "9:30 PM",
  "10:00 PM",
  "10:30 PM",
  "11:00 PM",
  "11:30 PM",
];

export const DAYS_OF_WEEK = [
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
  "SUNDAY",
];

export const SENDGRID_STATUS_MAP: { [index: string]: number } = {
  group_unsubscribe: 10,
  delivered: 2,
  group_resubscribe: 9,
  spam_report: 11,
  bounce: 3,
  deferred: 4,
  unsubscribe: 8,
  processed: 1,
  open: 6,
  click: 7,
  dropped: 5,
};

export const MAX_LIMIT_ENTERPRISE = 400;
export const MAX_LIMIT_NON_ENTERPRISE = 40;
export const MAX_LIMIT_ENTERPRISE_ADDITIONAL_USERS = 10;
export const MAX_LIMIT_NON_ENTERPRISE_ADDITIONAL_USERS = 3;

export const DISCOUNT_TYPE = {
  AMOUNT: "AMOUNT",
  PERCENTAGE: "PERCENTAGE",
};

export const FILE_TYPE = {
  AOI_1: "AOI_1",
  AOI_2: "AOI_2",
  AOI_3: "AOI_3",
  W9_FORM: "W9_FORM",
  INSURANCE_CERT: "INSURANCE_CERT",
  USER_ACTIVITY_LOGS: "USER_ACTIVITY_LOGS",
  READ_ADMIN_ACTIVITY_LOGS: "READ_ADMIN_ACTIVITY_LOGS",
  ADMIN_BLOG_IMAGE: "ADMIN_BLOG_IMAGE",
  EMAIL_HISTORY_LOGS: "EMAIL_HISTORY_LOGS",
  TRANSACTION_RECEIPT: "TRANSACTION_RECEIPT",
};

export const labels = [
  {
    label: "Blocked Merchant",
    value: "BLOCKED_MERCHANT",
    stringValue: "BLOCKED_MERCHANT",
  },
  {
    label: "Cardholder Inactive",
    value: "CARDHOLDER_INACTIVE",
    stringValue: "CARDHOLDER_INACTIVE",
  },
  {
    label: "Cardholder Verification Required",
    value: "CARDHOLDER_VERIFICATION_REQUIRED",
    stringValue: "CARDHOLDER_VERIFICATION_REQUIRED",
  },
  {
    label: "Card Inactive",
    value: "CARD_INACTIVE",
    stringValue: "CARD_INACTIVE",
  },
  {
    label: "Credit Limit Reached",
    value: "CREDIT_LIMIT_REACHED",
    stringValue: "CREDIT_LIMIT_REACHED",
  },
  {
    label: "Insufficient Funds",
    value: "INSUFFICIENT_FUNDS",
    stringValue: "INSUFFICIENT_FUNDS",
  },
  {
    label: "Not Allowed",
    value: "NOT_ALLOWED",
    stringValue: "NOT_ALLOWED",
  },
  {
    label: "Outside Operational Hours",
    value: "OUTSIDE_OPERATIONAL_HOURS",
    stringValue: "OUTSIDE_OPERATIONAL_HOURS",
  },
  {
    label: "Restricted Merchant",
    value: "RESTRICTED_MERCHANT",
    stringValue: "RESTRICTED_MERCHANT",
  },
  {
    label: "Spending Control",
    value: "SPENDING_CONTROLS",
    stringValue: "SPENDING_CONTROLS",
  },
  {
    label: "Suspected Fraud",
    value: "SUSPECTED_FRAUD",
    stringValue: "SUSPECTED_FRAUD",
  },
  {
    label: "Verification Failed",
    value: "VERIFICATION_FAILED",
    stringValue: "VERIFICATION_FAILED",
  },
  {
    label: "Telematics Security",
    value: "TELEMATICS_SECURITY",
    stringValue: "TELEMATICS_SECURITY",
  },
];

export const flaggedTransactionLabels = [
  {
    label: "Merchant Category",
    value: "FLAGGED_MERCHANT_CATEGORY",
    stringValue: "FLAGGED_MERCHANT_CATEGORY",
  },
  {
    label: "Fuel Type",
    value: "FUEL_TYPE",
    stringValue: "FUEL_TYPE",
  },
  {
    label: "Suspicious Fueling",
    value: "SUSPICIOUS_FUELING",
    stringValue: "SUSPICIOUS_FUELING",
  },
  {
    label: "Tank Capacity",
    value: "TANK_CAPACITY",
    stringValue: "TANK_CAPACITY",
  },
];
export const fuelTypesNotificationLabels = [
  {
    label: "Diesel",
    value: "FUEL_TYPE_DIESEL",
    stringValue: "FUEL_TYPE_DIESEL",
  },
  {
    label: "Unleaded Plus",
    value: "FUEL_TYPE_UNLEADED_PLUS",
    stringValue: "FUEL_TYPE_UNLEADED_PLUS",
  },
  {
    label: "Unleaded Regular",
    value: "FUEL_TYPE_UNLEADED_REGULAR",
    stringValue: "FUEL_TYPE_UNLEADED_REGULAR",
  },
  {
    label: "Unleaded Super",
    value: "FUEL_TYPE_UNLEADED_SUPER",
    stringValue: "FUEL_TYPE_UNLEADED_SUPER",
  },
  {
    label: "Other",
    value: "FUEL_TYPE_OTHER",
    stringValue: "FUEL_TYPE_OTHER",
  },
];
export const balanceLabels = [
  {
    label: "$30.00",
    value: "3000",
    stringValue: "3000",
  },
  {
    label: "$50.00",
    value: "5000",
    stringValue: "5000",
  },
  {
    label: "$100.00",
    value: "10000",
    stringValue: "10000",
  },
  {
    label: "$500.00",
    value: "50000",
    stringValue: "50000",
  },
  {
    label: "$2000.00",
    value: "200000",
    stringValue: "200000",
  },
];
export const transactionAboveLabels = [
  {
    label: "$30.00",
    value: "3000",
    stringValue: "3000",
  },
  {
    label: "$50.00",
    value: "5000",
    stringValue: "5000",
  },
  {
    label: "$100.00",
    value: "10000",
    stringValue: "10000",
  },
  {
    label: "$500.00",
    value: "50000",
    stringValue: "50000",
  },
];

export const DEFAULT_OPTIONS = {
  ["LOW_BALANCE"]: {
    communicationMode: "NONE",
    category: "ACCOUNT",
    description: `Notify when account balance is low`,
    isSmsDisabled: false,
    isEmailDisabled: true,
    variables: "3000",
    rank: 1,
  },
  ["ACCOUNT_LOCKED"]: {
    communicationMode: "EMAIL",
    category: "ACCOUNT",
    description: `Notify when account is locked`,
    isSmsDisabled: false,
    isEmailDisabled: false,
    rank: 2,
  },
  ["STATEMENT_AVAILABLE"]: {
    communicationMode: "EMAIL",
    category: "BILLING_AND_PAYMENT",
    description: `Notify when a statement is available`,
    isSmsDisabled: true,
    isEmailDisabled: false,
    rank: 1,
  },
  ["TRANSACTION_DECLINED"]: {
    communicationMode: "SMS",
    category: "TRANSACTION",
    optionValues: [
      "OUTSIDE_OPERATIONAL_HOURS",
      "RESTRICTED_MERCHANT",
      "BLOCKED_MERCHANT",
      "TELEMATICS_SECURITY",
    ],
    description: `Notify when a transaction is declined`,
    isSmsDisabled: false,
    isEmailDisabled: true,
    rank: 1,
  },
  ["TRANSACTION_ABOVE_AMOUNT"]: {
    communicationMode: "NONE",
    category: "TRANSACTION",
    description: `Notify when a transaction is approved and exceeds a specific amount`,
    variables: "10000",
    isSmsDisabled: true,
    isEmailDisabled: false,
    rank: 2,
  },
  ["TRANSACTION_FUEL_TYPE"]: {
    communicationMode: "NONE",
    category: "TRANSACTION",
    optionValues: [
      "FUEL_TYPE_DIESEL",
      "FUEL_TYPE_UNLEADED_PLUS",
      "FUEL_TYPE_UNLEADED_REGULAR",
      "FUEL_TYPE_UNLEADED_SUPER",
      "FUEL_TYPE_OTHER",
    ],
    description: `Notify when a fuel transaction uses specified fuel type`,
    isSmsDisabled: true,
    isEmailDisabled: false,
    rank: 3,
  },
  ["TRANSACTION_FLAGGED"]: {
    communicationMode: "NONE",
    category: "TRANSACTION",
    description: `Notify when a transaction is flagged`,
    optionValues: ["FUEL_TYPE", "TANK_CAPACITY"],
    isSmsDisabled: true,
    isEmailDisabled: false,
    rank: 4,
  },
  ["TRANSACTION_APPROVED"]: {
    communicationMode: "NONE",
    category: "TRANSACTION",
    description: `Notify when a transaction is successful`,
    optionValues: [],
    isSmsDisabled: true,
    isEmailDisabled: false,
    rank: 5,
  },
  ["ANALYSIS_DAILY"]: {
    communicationMode: "NONE",
    category: "ANALYSIS",
    description: `Receive daily analysis reports`,
    isSmsDisabled: true,
    isEmailDisabled: false,
    rank: 1,
  },
  ["ANALYSIS_WEEKLY"]: {
    communicationMode: "NONE",
    category: "ANALYSIS",
    description: `Receive weekly analysis reports`,
    isSmsDisabled: true,
    isEmailDisabled: false,
    rank: 2,
  },
};
export const DEFAULT_OPTIONS_FOR_CHILD_USERS = {
  ["TRANSACTION_DECLINED"]: {
    communicationMode: "NONE",
    category: "TRANSACTION",
    optionValues: [
      "OUTSIDE_OPERATIONAL_HOURS",
      "RESTRICTED_MERCHANT",
      "BLOCKED_MERCHANT",
      "TELEMATICS_SECURITY",
    ],
    description: "Notify when a transaction is declined",
    isSmsDisabled: false,
    isEmailDisabled: false,
    rank: 1,
  },
  ["TRANSACTION_ABOVE_AMOUNT"]: {
    communicationMode: "NONE",
    category: "TRANSACTION",
    description: `Notify when a transaction is approved and exceeds a specific amount`,
    variables: "10000",
    isSmsDisabled: true,
    isEmailDisabled: false,
    rank: 2,
  },
  ["TRANSACTION_FUEL_TYPE"]: {
    communicationMode: "NONE",
    category: "TRANSACTION",
    optionValues: [
      "FUEL_TYPE_DIESEL",
      "FUEL_TYPE_UNLEADED_PLUS",
      "FUEL_TYPE_UNLEADED_REGULAR",
      "FUEL_TYPE_UNLEADED_SUPER",
      "FUEL_TYPE_OTHER",
    ],
    description: `Notify when a fuel transaction uses specified fuel type`,
    isSmsDisabled: true,
    isEmailDisabled: false,
    rank: 3,
  },
  ["TRANSACTION_FLAGGED"]: {
    communicationMode: "NONE",
    category: "TRANSACTION",
    optionValues: ["FUEL_TYPE", "TANK_CAPACITY"],
    description: "Notify when a transaction is flagged",
    isSmsDisabled: false,
    isEmailDisabled: true,
    rank: 4,
  },
  ["TRANSACTION_APPROVED"]: {
    communicationMode: "NONE",
    category: "TRANSACTION",
    description: `Notify when a transaction is successful`,
    optionValues: ["automated_fuel_dispensers", "service_stations"],
    isSmsDisabled: true,
    isEmailDisabled: false,
    rank: 5,
  },
};

export const NOTIFICATION_VALUES_CLASSIFICATION = {
  ["TRANSACTION_DECLINED"]: [
    "BLOCKED_MERCHANT",
    "CARDHOLDER_INACTIVE",
    "CARDHOLDER_VERIFICATION_REQUIRED",
    "CARD_INACTIVE",
    "CREDIT_LIMIT_REACHED",
    "INSUFFICIENT_FUNDS",
    "NOT_ALLOWED",
    "OUTSIDE_OPERATIONAL_HOURS",
    "RESTRICTED_MERCHANT",
    "SPENDING_CONTROLS",
    "SUSPECTED_FRAUD",
    "VERIFICATION_FAILED",
    "TELEMATICS_SECURITY",
  ],
  ["TRANSACTION_FLAGGED"]: [
    "FUEL_TYPE",
    "TANK_CAPACITY",
    "SUSPICIOUS_FUELING",
    "FLAGGED_MERCHANT_CATEGORY",
  ],
  ["TRANSACTION_FUEL_TYPE"]: [
    "FUEL_TYPE_DIESEL",
    "FUEL_TYPE_UNLEADED_PLUS",
    "FUEL_TYPE_UNLEADED_REGULAR",
    "FUEL_TYPE_UNLEADED_SUPER",
    "FUEL_TYPE_OTHER",
  ],
};

export const HIGHNOTE_CARD_PROFILES = [
  {
    name: "Fleet, No Restrictions (Fuel, Maintenance, and Non-Fuel)",
    cardProfileId: "icpse_085178cfefed4b0d981433df32c79282",
  },
  {
    name: "Fleet (Fuel and Maintenance Only)",
    cardProfileId: "icpse_135bff16fc90408c9694ff9ec3535693",
  },
  {
    name: "Fleet (Fuel Only)",
    cardProfileId: "icpse_5f46cd29c83d429b961561ef38df484e",
  },
];

export const DECLINE_REASON_DESCRIPTION = {
  SUSPECTED_FRAUD: "Suspected fraud",
  TELEMATICS_SECURITY: "Vehicle not present at merchant location",
  SPENDING_CONTROLS: "Transaction exceeds the spend limit",
  RESTRICTED_MERCHANT: "Restricted merchant type",
  BLOCKED_MERCHANT: "Unauthorized merchant type",
  OUTSIDE_OPERATIONAL_HOURS: "Outside of allowed working hours",
};

export const FUEL_PRODUCT_CODE = {
  "001": {
    shortName: "REGULAR",
    longName: "Regular",
    category: "Fuel",
    subCategory: "Dispensed Gasoline Regular Gasoline",
  },
  "002": {
    shortName: "PLUS",
    longName: "Mid/Plus",
    category: "Fuel",
    subCategory: "Dispensed Gasoline Mid/Plus Gasoline",
  },
  "003": {
    shortName: "PREMIUM",
    longName: "Premium/Super",
    category: "Fuel",
    subCategory: "Dispensed Gasoline Premium/Super Gasoline",
  },
  "004": {
    shortName: "PLUS2",
    longName: "Mid/Plus 2",
    category: "Fuel",
    subCategory: "Dispensed Gasoline Mid/Plus Gasoline",
  },
  "005": {
    shortName: "PREMIUM2",
    longName: "Premium/Super 2",
    category: "Fuel",
    subCategory: "Dispensed Gasoline Premium/Super Gasoline",
  },
  "006": {
    shortName: "FUTURE006",
    longName: undefined,
    category: "Fuel",
    subCategory: "Dispensed Gasoline Regular Gasoline",
  },
  "007": {
    shortName: "FUTURE007",
    longName: undefined,
    category: "Fuel",
    subCategory: "Dispensed Gasoline Mid/Plus Gasoline",
  },
  "008": {
    shortName: "FUTURE008",
    longName: undefined,
    category: "Fuel",
    subCategory: "Dispensed Gasoline Premium/Super Gasoline",
  },
  "009": {
    shortName: "FUTURE006",
    longName: undefined,
    category: "Fuel",
    subCategory: "Dispensed Gasoline Regular Gasoline",
  },
  "010": {
    shortName: "FUTURE010",
    longName: undefined,
    category: "Fuel",
    subCategory: "Dispensed Gasoline Mid/Plus Gasoline",
  },
  "011": {
    shortName: "REG E5",
    longName: "Regular Ethanol 5% Blend) (non-US)",
    category: "Fuel",
    subCategory: "Dispensed Gasoline Mid/PlusRegular Gasoline",
  },
  "012": {
    shortName: "PLUS E5",
    longName: "Mid/Plus Ethanol (5% Blend) (non-US)",
    category: "Fuel",
    subCategory: "Dispensed Gasoline Mid/Plus Gasoline",
  },
  "013": {
    shortName: "PREM E5",
    longName: "Premium/Super Ethanol (5% Blend) (non-US)",
    category: "Fuel",
    subCategory: "Dispensed Gasoline Premium/Super Gasoline",
  },
  "014": {
    shortName: "FUTURE014",
    longName: undefined,
    category: "Fuel",
    subCategory: "Dispensed Gasoline Regular Gasoline",
  },
  "015": {
    shortName: "PLUS2E5",
    longName: "Mid/Plus2 Ethanol (5% Blend) (non-US)",
    category: "Fuel",
    subCategory: "Dispensed Gasoline Mid/Plus Gasoline",
  },
  "016": {
    shortName: "GREEN REG",
    longName: "Green Gasoline Regular (Plant based not petroleum based)",
    category: "Fuel",
    subCategory: "Dispensed Gasoline Regular Gasoline",
  },
  "017": {
    shortName: "GREEN PLUS",
    longName: "Green Gasoline Mid/Plus (Plant based not petroleum based)",
    category: "Fuel",
    subCategory: "Dispensed Gasoline Mid/Plus Gasoline",
  },
  "018": {
    shortName: "GREEN PREM",
    longName: "Green Gasoline Premium/Super (Plant based not petroleum based)",
    category: "Fuel",
    subCategory: "Dispensed Gasoline Premium/Super Gasoline",
  },
  "019": {
    shortName: "DIESEL 2",
    longName: "Regular Diesel #2",
    category: "Fuel",
    subCategory: "Dispensed Diesel",
  },
  "020": {
    shortName: "DIESEL 2",
    longName: "Premium Diesel #2",
    category: "Fuel",
    subCategory: "Dispensed Diesel",
  },
  "021": {
    shortName: "DIESEL 1",
    longName: "Regular Diesel #1",
    category: "Fuel",
    subCategory: "Dispensed Diesel",
  },
  "022": {
    shortName: "CNG",
    longName: "Compressed Natural Gas",
    category: "Fuel",
    subCategory: "Dispensed CNG, LNG",
  },
  "023": {
    shortName: "LPG",
    longName: "Liquid Propane Gas",
    category: "Fuel",
    subCategory: "Dispensed CNG, LNG",
  },
  "024": {
    shortName: "LNG",
    longName: "Liquid Natural Gas",
    category: "Fuel",
    subCategory: "Dispensed CNG, LNG",
  },
  "025": {
    shortName: "FUTURE025",
    longName: undefined,
    category: "Fuel",
    subCategory: "Dispensed Gasoline",
  },
  "026": {
    shortName: "E85",
    longName: "E-85",
    category: "Fuel",
    subCategory: "Dispensed Gasoline",
  },
  "027": {
    shortName: "REG RFRM",
    longName: "Regular Reformulated",
    category: "Fuel",
    subCategory: "Dispensed Gasoline Regular Gasoline",
  },
  "028": {
    shortName: "PLUS RFRM",
    longName: "Mid/Plus Reformulated",
    category: "Fuel",
    subCategory: "Dispensed Gasoline Mid/Plus Gasoline",
  },
  "029": {
    shortName: "PREM RFRM",
    longName: "Premium/Super Reformulated",
    category: "Fuel",
    subCategory: "Dispensed Gasoline",
  },
  "030": {
    shortName: "PLUS2 RFRM",
    longName: "Mid/Plus 2 Reformulated",
    category: "Fuel",
    subCategory: "Dispensed Gasoline",
  },
  "031": {
    shortName: "PREM2 RFRM",
    longName: "Premium/Super 2 Reformulated",
    category: "Fuel",
    subCategory: "Dispensed Gasoline",
  },
  "032": {
    shortName: "DSLBLD OFF",
    longName: "Diesel Off-Road (#1 and #2 Non-Taxable)",
    category: "Fuel",
    subCategory: "Dispensed Off-Road fuels",
  },
  "033": {
    shortName: "DSL OFF",
    longName: "Diesel Off-Road (Non-Taxable)",
    category: "Fuel",
    subCategory: "Dispensed Off-Road fuels",
  },
  "034": {
    shortName: "BIODSL OFF",
    longName: "Biodiesel Blend Off-Road (Non-Taxable)",
    category: "Fuel",
    subCategory: "Dispensed Off-Road fuels",
  },
  "035": {
    shortName: "FUTURE035",
    longName: "Undefined Fuel - Reserved for Conexxus Future",
    category: "Fuel",
    subCategory: "Dispensed Off-Road fuels",
  },
  "036": {
    shortName: "RACING",
    longName: "Racing Fuel",
    category: "Fuel",
    subCategory: "Dispensed Gasoline",
  },
  "037": {
    shortName: "PLUS 2 E10",
    longName: "Mid/Plus 2 (10% Blend)",
    category: "Fuel",
    subCategory: "Dispensed Gasoline Mid/Plus Gasoline",
  },
  "038": {
    shortName: "PREM 2 E10",
    longName: "Premium/Super 2 (10% Blend)",
    category: "Fuel",
    subCategory: "Dispensed Gasoline Premium/Super Gasoline",
  },
  "039": {
    shortName: "PLUS 2 E15",
    longName: "Mid/Plus Ethanol 2 (15% Blend)",
    category: "Fuel",
    subCategory: "Dispensed Gasoline Mid/Plus Gasoline",
  },
  "040": {
    shortName: "PREM 2 E15",
    longName: "Premium/Super Ethanol 2 (15% Blend)",
    category: "Fuel",
    subCategory: "Dispensed Gasoline Premium/Super Gasoline",
  },
  "041": {
    shortName: "PREM2 E5",
    longName: "Premium/Super2 Ethanol (5% Blend) (non-US)",
    category: "Fuel",
    subCategory: "Dispensed Gasoline Premium/Super Gasoline",
  },
  "042": {
    shortName: "REG E10",
    longName: "Regular Ethanol (10% Blend)",
    category: "Fuel",
    subCategory: "Dispensed Gasoline Regular Gasoline",
  },
  "043": {
    shortName: "PLUS E10",
    longName: "Mid/Plus Ethanol (10% Blend)",
    category: "Fuel",
    subCategory: "Dispensed Gasoline Mid/Plus Gasoline",
  },
  "044": {
    shortName: "PREM E10",
    longName: "Premium/Super Ethanol (10% Blend)",
    category: "Fuel",
    subCategory: "Dispensed Gasoline Premium/Super Gasoline",
  },
  "045": {
    shortName: "BIODSL 2%",
    longName: "B2 Diesel Blend 2% Biodiesel",
    category: "Fuel",
    subCategory: "Dispensed Diesel",
  },
  "046": {
    shortName: "BIODSL 5%",
    longName: "B5 Diesel Blend 5% Biodiesel",
    category: "Fuel",
    subCategory: "Dispensed Diesel",
  },
  "047": {
    shortName: "BIODSL 10%",
    longName: "B10 Diesel Blend 10% Biodiesel",
    category: "Fuel",
    subCategory: "Dispensed Diesel",
  },
  "048": {
    shortName: "BIODSL 11%",
    longName: "B11 Diesel Blend 11% Biodiesel",
    category: "Fuel",
    subCategory: "Dispensed Diesel",
  },
  "049": {
    shortName: "BIODSL 15%",
    longName: "B15 Diesel Blend 15% Biodiesel",
    category: "Fuel",
    subCategory: "Dispensed Diesel",
  },
  "050": {
    shortName: "BIODSL 20%",
    longName: "B20 Diesel Blend 20% Biodiesel",
    category: "Fuel",
    subCategory: "Dispensed Diesel",
  },
  "051": {
    shortName: "BIODSL 100",
    longName: "B100 Diesel Blend 100% Biodiesel",
    category: "Fuel",
    subCategory: "Dispensed Diesel",
  },
  "052": {
    shortName: "BIODSL 1%",
    longName: "B1 Diesel Blend 1% Biodiesel",
    category: "Fuel",
    subCategory: "Dispensed Diesel",
  },
  "053": {
    shortName: "DSL ADD 2",
    longName: "Additized Diesel #2",
    category: "Fuel",
    subCategory: "Dispensed Diesel",
  },
  "054": {
    shortName: "DSL ADD 3",
    longName: "Additized Diesel #3",
    category: "Fuel",
    subCategory: "Dispensed Diesel",
  },
  "055": {
    shortName: "BIODSL 7%",
    longName: "B7 Diesel Blend 7% Biodiesel (non-US)",
    category: "Fuel",
    subCategory: "Dispensed Diesel",
  },
  "056": {
    shortName: "BIODSL7%PR",
    longName: "B7 Premium Diesel Blend 7% Biodiesel (non-US)",
    category: "Fuel",
    subCategory: "Dispensed Diesel",
  },
  "057": {
    shortName: "FUTURE057",
    longName: "Undefined Fuel - Reserved for Conexxus Future Use",
    category: "Fuel",
    subCategory: "Dispensed Diesel",
  },
  "058": {
    shortName: "FUTURE058",
    longName: "Undefined Fuel - Reserved for Conexxus Future Use",
    category: "Fuel",
    subCategory: "Dispensed Diesel",
  },
  "059": {
    shortName: "FUTURE059",
    longName: "Undefined Fuel - Reserved for Conexxus Future Use",
    category: "Fuel",
    subCategory: "Dispensed Diesel",
  },
  "060": {
    shortName: "REN DSL 2",
    longName:
      "Renewable Diesel (>=R95) (meets ASTM D975 in US / EN 590 in Europe)",
    category: "Fuel",
    subCategory: "Dispensed Diesel",
  },
  "061": {
    shortName: "RD80 BIO20",
    longName: "Renewable Diesel (Biodiesel 6%-20%) (meets ASTM D7467 in US)",
    category: "Fuel",
    subCategory: "Dispensed Diesel",
  },
  "062": {
    shortName: "DEF",
    longName: "DEF (Diesel Exhaust Fluid) / AdBlue Pump",
    category: "Fuel",
    subCategory: "Dispensed Diesel Dispensed Off-Road fuels",
  },
  "063": {
    shortName: "DSL 1 PREM",
    longName: "Premium Diesel #1",
    category: "Fuel",
    subCategory: "Dispensed Diesel",
  },
  "064": {
    shortName: "REG E15",
    longName: "Regular Ethanol (15% Blend)",
    category: "Fuel",
    subCategory: "Dispensed Gasoline Regular Gasoline",
  },
  "065": {
    shortName: "PLUS E15",
    longName: "Mid/Plus Ethanol (15% Blend)",
    category: "Fuel",
    subCategory: "Dispensed Gasoline Mid/Plus Gasoline",
  },
  "066": {
    shortName: "PREM E15",
    longName: "Premium/Super Ethanol (15% Blend)",
    category: "Fuel",
    subCategory: "Dispensed Gasoline Premium/Super Gasoline",
  },
  "067": {
    shortName: "PRMDSL <20",
    longName: "Premium Diesel Blend <20% Biodiesel",
    category: "Fuel",
    subCategory: "Dispensed Diesel",
  },
  "068": {
    shortName: "PRMDSL >20",
    longName: "Premium Diesel Blend >= 20% Biodiesel",
    category: "Fuel",
    subCategory: "Dispensed Diesel",
  },
  "069": {
    shortName: "PRMDSL B75",
    longName: "B75 Diesel Blend 75% Biodiesel",
    category: "Fuel",
    subCategory: "Dispensed Diesel",
  },
  "070": {
    shortName: "PRMDSL B99",
    longName: "B99 Diesel Blend 99% Biodiesel",
    category: "Fuel",
    subCategory: "Dispensed Diesel",
  },
};
