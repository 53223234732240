/** @format */

import { usePasswordReset } from "@roadflex/react-hooks-www";
import { deleteCookie } from "@roadflex/web-lib";
import { Toast } from "../../../src/toast-message/toast";
import ResetPasswordUI from "./reset-password.ui";

type Props = {
  token: string;
};

export function ResetPassword({ token }: Props) {
  const {
    values,
    handleChange,
    handleSubmit,
    isSubmitting,
    errors,
    touched,
    handleBlur,
    ...rest
  } = usePasswordReset({
    initialValues: {
      password: "",
      retypePassword: "",
      token,
    },
    onSubmit(res, err) {
      if (err) {
        Toast({
          type: "error",
          message: err.message,
        });
        return;
      }
      if (res?.code === "200") {
        Toast({
          type: "success",
          message: res.message,
        });
        deleteCookie("token");
        window.location.href = "/signin";
      }
    },
  });

  return (
    <ResetPasswordUI
      {...{
        values,
        handleChange,
        handleSubmit,
        isSubmitting,
        errors,
        touched,
        handleBlur,
        ...rest,
      }}
    />
  );
}
