/** @format */
import { useMutation } from "@apollo/client";
import { genericPasswordSchema } from "@roadflex/constants";
import { PASSWORD_RESET } from "@roadflex/graphql";
import { ResetPasswordValues } from "@roadflex/types";
import { useFormik } from "formik";

type usePasswordResetProps = {
  onSubmit: (val: any, err: any) => void;
  initialValues: ResetPasswordValues;
};

export const usePasswordReset = ({
  onSubmit,
  initialValues,
}: usePasswordResetProps) => {
  const [passwordResetFn] = useMutation<{
    passwordReset: {
      code: string;
      message: string;
    };
  }>(PASSWORD_RESET);

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    isSubmitting,
    ...rest
  } = useFormik({
    initialValues,
    validationSchema: genericPasswordSchema,
    onSubmit: async (value) => {
      try {
        const res = await passwordResetFn({
          variables: { data: value },
        });
        if (res?.data?.passwordReset) {
          onSubmit(res?.data?.passwordReset, null);
        }
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });

  return {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    ...rest,
  };
};
