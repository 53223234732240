import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { MissingDetailsUpdateValues } from "@roadflex/types";
import { einFormatter, ssnFormatter } from "@roadflex/web-lib";
import classNames from "classnames";
import { FormikProps } from "formik";
import { Tooltip } from "primereact/tooltip";
import { Button, ButtonType, ButtonVariant } from "../buttons";

type MissingDetailsUpdateUIProps = FormikProps<MissingDetailsUpdateValues> & {
  ein: string;
  ssn: string;
  UpdatingDetails: boolean;
};
export const MissingDetailsUpdateUI = (props: MissingDetailsUpdateUIProps) => {
  const {
    values,
    handleBlur,
    handleSubmit,
    isSubmitting,
    errors,
    touched,
    setFieldValue,
    ein,
    ssn,
    UpdatingDetails,
  } = props;
  return (
    <div className="flex-1 w-full h-full bg-cover bg-onboard-texture">
      <form
        className="container flex flex-col items-center justify-center h-full my-8 "
        onSubmit={handleSubmit}
      >
        <div className="">
          <div
            className="px-4 py-8 sm:px-8 sm:py-8 border-2 rounded-xl  w-full md:w-[550px]"
            style={{
              background: "linear-gradient(130deg,#29120A 0, #823920 100%)",
            }}
          >
            <div className="mb-6 text-2xl text-white">
              Please update your details before accessing the dashboard
            </div>
            <div className="">
              {!ssn && (
                <div>
                  <div className="mb-1 text-xs text-white whitespace-nowrap lg:text-sm">
                    SSN*
                  </div>
                  <div className="relative w-full ">
                    <input
                      name="ssn"
                      value={values.ssn}
                      onChange={(event) =>
                        setFieldValue("ssn", ssnFormatter(event?.target?.value))
                      }
                      onBlur={handleBlur}
                      className={classNames(
                        touched.ssn && errors?.ssn ? "border-red-500" : "",
                        "block w-full pl-3 pr-8 py-2 border rounded focus:outline-none",
                      )}
                    />
                    {touched.ssn && errors?.ssn && (
                      <>
                        <Tooltip
                          target=".ssn-error"
                          position="top"
                          className="red-tooltip"
                          content={errors.ssn}
                        ></Tooltip>

                        <div className="absolute h-[42px] bottom-0 right-0 pr-3 flex items-center">
                          <ExclamationCircleIcon
                            className="w-5 h-5 text-red-500 ssn-error"
                            aria-hidden="true"
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}
              {!ein && (
                <div className="mt-4">
                  <div className="mb-1 text-xs text-white whitespace-nowrap lg:text-sm">
                    Employer Identification Number (EIN - 9 digit)*
                  </div>
                  <div className="relative w-full">
                    <input
                      type="text"
                      name="ein"
                      value={values.ein}
                      onChange={(event) =>
                        setFieldValue("ein", einFormatter(event?.target?.value))
                      }
                      onBlur={handleBlur}
                      className={classNames(
                        touched?.ein && errors?.ein
                          ? "border-red-500 focus:border-red-500 border-2"
                          : "",
                        "block w-full pl-3 pr-8 py-2 border border-gray-300 rounded focus:outline-none focus:ring-0 focus:shadow-none",
                      )}
                    />
                    {touched.ein && errors?.ein && (
                      <>
                        <Tooltip
                          target=".ein-error"
                          position="top"
                          className="red-tooltip"
                          content={errors.ein}
                        ></Tooltip>

                        <div className="absolute h-[44px] bottom-0 right-0 pr-3 flex items-center cursor-pointer">
                          <ExclamationCircleIcon
                            className="w-5 h-5 text-red-500 ein-error"
                            aria-hidden="true"
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}
              <div className="mt-4">
                <Button
                  type={ButtonType.Submit}
                  variant={ButtonVariant.Orange}
                  className="w-full"
                  loading={isSubmitting || UpdatingDetails}
                  disabled={isSubmitting || UpdatingDetails}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
