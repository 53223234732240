/** @format */

import { useMutation } from "@apollo/client";
import { SEND_VERIFICATION_EMAIL_OR_SMS } from "@roadflex/graphql";
import {
  usePhoneVerification,
  useReadCurrentUserBasicInformation,
} from "@roadflex/react-hooks-www";
import { useState } from "react";
import Redirect from "../redirect/redirect";
import { Toast } from "../toast-message/toast";
import { PhoneVerificationUI } from "./phone-verification.ui";

export const PhoneVerification = () => {
  const {
    readCurrentUserBasic,
    loading,
    refetch: readCurrentUserBasicRefetch,
  } = useReadCurrentUserBasicInformation();
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [sendSmsFn, { loading: sendingOTP }] = useMutation(
    SEND_VERIFICATION_EMAIL_OR_SMS,
    {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
    },
  );

  const {
    values,
    handleChange,
    handleSubmit,
    isSubmitting,
    touched,
    errors,
    handleBlur,
    setFieldValue,
    submitForm,
    ...rest
  } = usePhoneVerification({
    initialValues: {
      phoneNumber: "",
      otp: "",
    },
    async onSubmit(res, err) {
      if (err) {
        Toast({ type: "error", message: err.message });
        setFieldValue("otp", "");
        return;
      }
      if (!res?.data?.updatePhone?.success) {
        setShowOtpModal(false);
        Toast({ type: "error", message: res?.data?.updatePhone?.message });
        setFieldValue("otp", "");
        return;
      }
      await readCurrentUserBasicRefetch();
    },
  });
  const onOtpSubmit = async () => {
    setSubmitting(true);
    try {
      await submitForm();
    } catch (err) {
      if (err instanceof Error) {
        Toast({ type: "error", message: err.message });
      } else {
        Toast({ type: "error", message: "Seomthing went wrong" });
      }
    } finally {
      setSubmitting(false);
    }
  };

  const sendOTP = async () => {
    try {
      await sendSmsFn({
        variables: {
          data: {
            phoneNumber:
              values.phoneNumber?.trim()?.match(/\d+/g)?.join("") || "",
          },
        },
      });
      setShowOtpModal(true);
    } catch (err) {
      if (err instanceof Error) {
        Toast({ type: "error", message: err.message });
      } else {
        Toast({ type: "error", message: "Seomthing went wrong" });
      }
    }
  };

  if (loading) {
    return null;
  }

  if (!readCurrentUserBasic || readCurrentUserBasic?.isPhoneVerified) {
    return <Redirect path="/signin"></Redirect>;
  }

  return (
    <PhoneVerificationUI
      {...{
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        errors,
        touched,
        setFieldValue,
        submitForm,
        showOtpModal,
        setShowOtpModal,
        onOtpSubmit,
        otpSubmitting: submitting,
        sendOTP,
        sendingOTP,
        ...rest,
      }}
    ></PhoneVerificationUI>
  );
};
