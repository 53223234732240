/** @format */

import { usePasswordForgot } from "@roadflex/react-hooks-www";
import { Toast } from "../../../src/toast-message/toast";
import ForgotPasswordUI from "./forgot-password.ui";

export function ForgotPassword() {
  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    isSubmitting,
    resetForm,
    ...rest
  } = usePasswordForgot({
    initialValues: {
      email: "",
    },
    onSubmit(res) {
      if (res.code === "200") {
        Toast({
          type: "success",
          message: res.message,
        });
      } else {
        Toast({
          type: "error",
          message: res?.message || "",
        });
      }
      resetForm();
    },
  });

  return (
    <ForgotPasswordUI
      {...{
        values,
        errors,
        handleChange,
        handleSubmit,
        isSubmitting,
        resetForm,
        ...rest,
      }}
    />
  );
}
