/** @format */

import { Menu, Transition } from "@headlessui/react";
import {
  ArrowRightCircleIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";
import {
  Bars3Icon,
  ChevronDownIcon,
  PhoneIcon,
} from "@heroicons/react/24/solid";
import { useScroll } from "@roadflex/react-hooks-www";
import { UserAuthType } from "@roadflex/types";
import {
  checkIfUserIsInImpersonatorMode,
  deleteCookie,
  getCookie,
} from "@roadflex/web-lib";
import classNames from "classnames";
import getConfig from "next/config";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { Fragment, useEffect, useState } from "react";
import { ButtonLink } from "../../../buttons/button-link/button-link";
import { Button } from "../../../buttons/button/button";
import { ButtonSize, ButtonVariant } from "../../../buttons/constants";

const { publicRuntimeConfig } = getConfig();

const { NEXT_PUBLIC_WWW_URL, NEXT_PUBLIC_CDN_URL } = publicRuntimeConfig;
/* eslint-disable-next-line */
export interface HeaderProps {
  readCurrentUserAuth?: UserAuthType | undefined;
}
export function Header(props: HeaderProps) {
  const [isImpersonatorMode, setImpersonatorMode] = useState(false);
  const productLinks = [
    { href: `${NEXT_PUBLIC_WWW_URL}/fleetcard`, label: "Fleet Card" },
    { href: `${NEXT_PUBLIC_WWW_URL}/prefunded`, label: "Prefunded Account" },
    { href: `${NEXT_PUBLIC_WWW_URL}/savings`, label: "Savings Calculator" },
    {
      href: `${NEXT_PUBLIC_WWW_URL}/control-and-reporting`,
      label: "Controls & Reporting",
    },
    {
      href: `${NEXT_PUBLIC_WWW_URL}/security-features`,
      label: "Security Features",
    },
    { href: `${NEXT_PUBLIC_WWW_URL}/integrations`, label: "Integrations" },
    // { href: `${NEXT_PUBLIC_WWW_URL}/sustainability`, label: "Sustainability" },
  ];
  const partnerLinks = [
    {
      href: `${NEXT_PUBLIC_WWW_URL}/partnership`,
      label: "Become a partner",
    },
    { href: `${NEXT_PUBLIC_WWW_URL}/ecosystem`, label: "Ecosystem" },
    // { href: `${NEXT_PUBLIC_WWW_URL}/broker`, label: "Brokers" },
    {
      href: `${NEXT_PUBLIC_WWW_URL}/affiliate-program`,
      label: "Affiliate Program",
    },
  ];
  const companyLinks = [
    { href: `${NEXT_PUBLIC_WWW_URL}/blog`, label: "Blog" },
    { href: `${NEXT_PUBLIC_WWW_URL}/in-the-news`, label: "In The News" },
    { href: `${NEXT_PUBLIC_WWW_URL}/career`, label: "Careers" },
    { href: `${NEXT_PUBLIC_WWW_URL}/veteran`, label: "Veterans" },
  ];

  const industryLinks = [
    {
      href: `${NEXT_PUBLIC_WWW_URL}/industry/construction`,
      label: "Construction",
    },
    {
      href: `${NEXT_PUBLIC_WWW_URL}/industry/final-mile-delivery`,
      label: "Final Mile Delivery",
    },
    { href: `${NEXT_PUBLIC_WWW_URL}/industry/hvac`, label: "HVAC" },
    {
      href: `${NEXT_PUBLIC_WWW_URL}/industry/landscaping`,
      label: "Landscaping",
    },
    {
      href: `${NEXT_PUBLIC_WWW_URL}/industry/passenger-transport`,
      label: "Passenger Transport",
    },
    {
      href: `${NEXT_PUBLIC_WWW_URL}/industry/pest-control`,
      label: "Pest Control",
    },
    { href: `${NEXT_PUBLIC_WWW_URL}/industry/trucking`, label: "Trucking" },
    { href: `${NEXT_PUBLIC_WWW_URL}/industry/utilities`, label: "Utilities" },
    {
      href: `${NEXT_PUBLIC_WWW_URL}/industry/waste-management`,
      label: "Waste Management",
    },
    {
      href: `${NEXT_PUBLIC_WWW_URL}/industry/plumbing`,
      label: "Plumbing",
    },
  ];

  const { readCurrentUserAuth } = props;
  const isScrolled = useScroll(0);
  const router = useRouter();
  const showLinks =
    router.pathname !== "/deposit" &&
    router.pathname !== "/no-access" &&
    router.pathname !== "/onboard" &&
    router.pathname !== "/partners/techsbook";
  const showSignUp =
    router.pathname === "/signin" || router.pathname === "/partners/techsbook";
  const showSignOut =
    router.pathname === "/phone-verification" ||
    router.pathname === "/details-update";
  const handleSignOut = () => {
    deleteCookie("token");
    router.push({
      pathname: `${NEXT_PUBLIC_WWW_URL}/signin`,
    });
  };
  const scrollToSignUp = () => {
    router.push({
      pathname: `${NEXT_PUBLIC_WWW_URL}/signup`,
      query: "",
    });
  };

  useEffect(() => {
    setImpersonatorMode(checkIfUserIsInImpersonatorMode());
    const handleTabClose = () => {
      if (getCookie("impersonatorToken")) {
        deleteCookie("impersonatorToken");
      }
    };

    const onBeforeUnloadFunc = (event: Event) => {
      if (getCookie("impersonatorToken")) {
        const e = event;
        // Cancel the event
        e.preventDefault();
        if (e) {
          e.returnValue = false; // Legacy method for cross browser support
        }
        return ""; // Legacy method for cross browser support
      }
      return true;
    };

    window.addEventListener("beforeunload", onBeforeUnloadFunc);
    window.addEventListener("unload", handleTabClose);
    return () => {
      window.removeEventListener("beforeunload", onBeforeUnloadFunc);
      window.removeEventListener("unload", handleTabClose);
    };
  }, [readCurrentUserAuth]);

  return (
    <header
      id="header"
      className={classNames(
        !isScrolled ? "bg-white" : "bg-white ",
        "flex flex-col md:flex-row h-16 fixed w-full z-10 border-b border-gray-300",
      )}
    >
      <div className="container 3xl:max-w-[1750px!important] flex flex-grow-1 w-full justify-between items-center py-2 text-base">
        <div className="flex flex-row h-full py-2">
          {router.pathname === "/partners/advantageone" && (
            <div className="flex flex-row">
              <Link href="https://www.advantageone.tech/" prefetch={false}>
                <a
                  href="https://www.advantageone.tech/"
                  className="relative w-20 sm:w-48 md:w-52 min-h-[10px] max-h-[36px] md:h-5/6 self-center"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    className="p-0 m-0 hover:cursor-pointer"
                    src={`${NEXT_PUBLIC_CDN_URL}/partners/images/advantage-one-logo.png`}
                    alt="RoadFlex partner logo"
                    layout="fill"
                  />
                </a>
              </Link>
              <div className="relative self-center w-2 h-2 ml-1 mr-0 md:w-6 md:h-6 md:ml-5 md:mr-1">
                <Image
                  className="p-0 m-0"
                  src={`${NEXT_PUBLIC_CDN_URL}/partners/images/partnership-plus.png`}
                  alt="RoadFlex partnership"
                  layout="fill"
                />
              </div>
              <Link href="/">
                <a
                  href="/"
                  className="relative w-20 sm:w-44 min-h-[10px] max-h-[36px] md:h-5/6 self-center"
                >
                  <Image
                    className="p-0 m-0 hover:cursor-pointer"
                    src={`${NEXT_PUBLIC_CDN_URL}/partners/images/roadflex-partner-logo.png`}
                    alt="RoadFlex logo"
                    layout="fill"
                  />
                </a>
              </Link>
            </div>
          )}
          {router.pathname === "/partners/fleetup" && (
            <div className="flex flex-row">
              <Link href="https://fleetup.com/" prefetch={false}>
                <a
                  href="https://fleetup.com/"
                  className="relative w-20 md:w-28 min-h-[10px] max-h-[36px] md:h-full self-center"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    className="p-0 m-0 hover:cursor-pointer"
                    src={`${NEXT_PUBLIC_CDN_URL}/partners/images/fleetup-logo.jpeg`}
                    alt="RoadFlex partner logo"
                    layout="fill"
                  />
                </a>
              </Link>
              <div className="relative self-center w-2 h-2 ml-1 mr-0 md:w-6 md:h-6 md:ml-5 md:mr-1">
                <Image
                  className="p-0 m-0"
                  src={`${NEXT_PUBLIC_CDN_URL}/partners/images/partnership-plus.png`}
                  alt="RoadFlex partnership"
                  layout="fill"
                />
              </div>
              <Link href="/">
                <a
                  href="/"
                  className="relative w-20 sm:w-44 min-h-[10px] max-h-[36px] md:h-5/6 self-center"
                >
                  <Image
                    className="p-0 m-0 hover:cursor-pointer"
                    src={`${NEXT_PUBLIC_CDN_URL}/partners/images/roadflex-partner-logo.png`}
                    alt="RoadFlex logo"
                    layout="fill"
                  />
                </a>
              </Link>
            </div>
          )}
          {router.pathname === "/partners/linxup" && (
            <div className="flex flex-row">
              <Link href="https://fleetup.com/" prefetch={false}>
                <a
                  href="https://fleetup.com/"
                  className="relative w-20 md:w-28 min-h-[10px] max-h-[36px] md:h-full self-center"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    className="p-0 m-0 hover:cursor-pointer"
                    src={`${NEXT_PUBLIC_CDN_URL}/partners/images/linxup/linxup-logo.png`}
                    alt="RoadFlex partner logo"
                    layout="fill"
                  />
                </a>
              </Link>
              <div className="relative self-center w-2 h-2 ml-1 mr-0 md:w-6 md:h-6 md:ml-5 md:mr-1">
                <Image
                  className="p-0 m-0"
                  src={`${NEXT_PUBLIC_CDN_URL}/partners/images/partnership-plus.png`}
                  alt="RoadFlex partnership"
                  layout="fill"
                />
              </div>
              <Link href="/">
                <a
                  href="/"
                  className="relative w-20 sm:w-44 min-h-[10px] max-h-[36px] md:h-5/6 self-center"
                >
                  <Image
                    className="p-0 m-0 hover:cursor-pointer"
                    src={`${NEXT_PUBLIC_CDN_URL}/partners/images/roadflex-partner-logo.png`}
                    alt="RoadFlex logo"
                    layout="fill"
                  />
                </a>
              </Link>
            </div>
          )}
          {router.pathname === "/partners/techsbook" && (
            <div className="flex flex-row">
              <Link href="https://techsbook.com/" prefetch={false}>
                <a
                  href="https://techsbook.com/"
                  className="relative w-20 md:w-28 min-h-[10px] max-h-[36px] md:h-full self-center"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    className="p-0 m-0 hover:cursor-pointer"
                    src={`${NEXT_PUBLIC_CDN_URL}/partners/images/techsbook-logo.png`}
                    alt="RoadFlex partner logo"
                    layout="fill"
                  />
                </a>
              </Link>
              <div className="relative self-center w-2 h-2 ml-1 mr-0 md:w-6 md:h-6 md:ml-5 md:mr-1">
                <Image
                  className="p-0 m-0"
                  src={`${NEXT_PUBLIC_CDN_URL}/partners/images/partnership-plus.png`}
                  alt="RoadFlex partnership"
                  layout="fill"
                />
              </div>
              <Link href="/">
                <a
                  href="/"
                  className="relative w-20 sm:w-44 min-h-[10px] max-h-[36px] md:h-5/6 self-center"
                >
                  <Image
                    className="p-0 m-0 hover:cursor-pointer"
                    src={`${NEXT_PUBLIC_CDN_URL}/partners/images/roadflex-partner-logo.png`}
                    alt="RoadFlex logo"
                    layout="fill"
                  />
                </a>
              </Link>
            </div>
          )}
          {router.pathname === "/partners/npma" && (
            <div className="flex flex-row">
              <Link href="https://www.npmapestworld.org/" prefetch={false}>
                <a
                  href="https://www.npmapestworld.org/"
                  className="relative w-20 md:w-28 min-h-[10px] max-h-[36px] md:h-full self-center"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    className="p-0 m-0 hover:cursor-pointer"
                    src={`${NEXT_PUBLIC_CDN_URL}/partners/images/npma/npma-logo.png`}
                    alt="RoadFlex partner logo"
                    layout="fill"
                  />
                </a>
              </Link>
              <div className="relative self-center w-2 h-2 ml-1 mr-0 md:w-6 md:h-6 md:ml-5 md:mr-1">
                <Image
                  className="p-0 m-0"
                  src={`${NEXT_PUBLIC_CDN_URL}/partners/images/partnership-plus.png`}
                  alt="RoadFlex partnership"
                  layout="fill"
                />
              </div>
              <Link href="/">
                <a
                  href="/"
                  className="relative w-20 sm:w-44 min-h-[10px] max-h-[36px] md:h-5/6 self-center"
                >
                  <Image
                    className="p-0 m-0 hover:cursor-pointer"
                    src={`${NEXT_PUBLIC_CDN_URL}/partners/images/roadflex-partner-logo.png`}
                    alt="RoadFlex logo"
                    layout="fill"
                  />
                </a>
              </Link>
            </div>
          )}
          {router.pathname === "/partners/nalp" && (
            <div className="flex flex-row">
              <Link
                href="https://www.landscapeprofessionals.org/"
                prefetch={false}
              >
                <a
                  href="https://www.landscapeprofessionals.org/"
                  className="relative w-20 md:w-28 min-h-[10px] max-h-[36px] md:h-full self-center"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    className="p-0 m-0 hover:cursor-pointer"
                    src={`${NEXT_PUBLIC_CDN_URL}/partners/images/nalp/nalp-logo.png`}
                    alt="RoadFlex partner logo"
                    layout="fill"
                  />
                </a>
              </Link>
              <div className="relative self-center w-2 h-2 ml-1 mr-0 md:w-6 md:h-6 md:ml-5 md:mr-1">
                <Image
                  className="p-0 m-0"
                  src={`${NEXT_PUBLIC_CDN_URL}/partners/images/partnership-plus.png`}
                  alt="RoadFlex partnership"
                  layout="fill"
                />
              </div>
              <Link href="/">
                <a
                  href="/"
                  className="relative w-20 sm:w-44 min-h-[10px] max-h-[36px] md:h-5/6 self-center"
                >
                  <Image
                    className="p-0 m-0 hover:cursor-pointer"
                    src={`${NEXT_PUBLIC_CDN_URL}/partners/images/roadflex-partner-logo.png`}
                    alt="RoadFlex logo"
                    layout="fill"
                  />
                </a>
              </Link>
            </div>
          )}

          {router.pathname !== "/partners/advantageone" &&
            router.pathname !== "/partners/fleetup" &&
            router.pathname !== "/partners/techsbook" &&
            router.pathname !== "/partners/nalp" &&
            router.pathname !== "/partners/npma" &&
            router.pathname !== "/partners/linxup" && (
              <Link href="/">
                <a
                  href="/"
                  className="relative w-40 sm:w-48 md:w-60 h-[30px] max-h-[36px] md:h-full self-center"
                >
                  {!isScrolled && router.pathname === "/" ? (
                    <Image
                      className="p-0 m-0 h-9 md:max-h-2 hover:cursor-pointer"
                      src={`${NEXT_PUBLIC_CDN_URL}/common/roadflex-logos/svg/roadflex-logo-black.svg`}
                      alt="RoadFlex logo"
                      layout="fill"
                      priority={true}
                    />
                  ) : (
                    <Image
                      className="p-0 m-0 h-9 hover:cursor-pointer"
                      src={`${NEXT_PUBLIC_CDN_URL}/common/roadflex-logos/svg/roadflex-logo-black.svg`}
                      alt="RoadFlex logo"
                      layout="fill"
                      priority={true}
                    />
                  )}
                </a>
              </Link>
            )}
        </div>
        <div className="flex flex-row items-center my-1 ml-4 text-sm sm:text-base">
          {isImpersonatorMode ? (
            <div className="relative px-2 py-1 my-3 mr-4 text-sm font-medium bg-red-200 rounded-md md:text-base sm:mr-6 sm:my-2">
              <div className="flex items-center gap-1">
                <ExclamationCircleIcon className="w-4 h-4 text-red-700 sm:w-5 sm:h-5" />
                <span className="text-red-600">Impersonator Mode</span>
              </div>
            </div>
          ) : null}

          {showLinks ? (
            <>
              <Menu
                as="div"
                className="relative hidden text-left lg:inline-block"
              >
                {({ open }) => (
                  <div>
                    <div>
                      <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 px-3 py-2 font-semibold text-black">
                        Why RoadFlex
                        <ChevronDownIcon
                          className="w-5 h-5 -mr-1 text-gray-800"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                    </div>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 w-56 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="">
                          <Menu.Item key="competition">
                            {({ active }) => (
                              <a
                                href={`${NEXT_PUBLIC_WWW_URL}/competition`}
                                className={classNames(
                                  active
                                    ? "bg-gray-100 text-gray-900"
                                    : "text-gray-700",
                                  "block px-4 py-3",
                                  "font-semibold flex items-center justify-between cursor-pointer",
                                )}
                              >
                                Vs. Competition{" "}
                                <ArrowRightCircleIcon className="w-4 h-4 ml-2 text-black sm:w-5 sm:h-5" />
                              </a>
                            )}
                          </Menu.Item>
                          <Menu.Item key="customer-success">
                            {({ active }) => (
                              <a
                                href={`${NEXT_PUBLIC_WWW_URL}/customer-success`}
                                className={classNames(
                                  active
                                    ? "bg-gray-100 text-gray-900"
                                    : "text-gray-700",
                                  "block px-4 py-3",
                                  "font-semibold flex items-center justify-between cursor-pointer",
                                )}
                              >
                                Customer Success{" "}
                                <ArrowRightCircleIcon className="w-4 h-4 ml-2 text-black sm:w-5 sm:h-5" />
                              </a>
                            )}
                          </Menu.Item>
                          <Menu.Item key="customer-stories">
                            {({ active }) => (
                              <a
                                href={`${NEXT_PUBLIC_WWW_URL}/customer-stories`}
                                className={classNames(
                                  active
                                    ? "bg-gray-100 text-gray-900"
                                    : "text-gray-700",
                                  "block px-4 py-3",
                                  "font-semibold flex items-center justify-between cursor-pointer",
                                )}
                              >
                                Customer Stories{" "}
                                <ArrowRightCircleIcon className="w-4 h-4 ml-2 text-black sm:w-5 sm:h-5" />
                              </a>
                            )}
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </div>
                )}
              </Menu>
              <Menu
                as="div"
                className="relative hidden text-left lg:inline-block"
              >
                {({ open }) => (
                  <>
                    <div>
                      <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 px-3 py-2 font-semibold text-black">
                        Product
                        <ChevronDownIcon
                          className="w-5 h-5 -mr-1 text-gray-800"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                    </div>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 w-56 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {productLinks.map((link, index) => (
                          <div key={link.href} className="">
                            {index !== 0 && (
                              <div className="border border-b-0 border-l-0 border-r-0 border-gray-300"></div>
                            )}
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href={link.href}
                                  className={classNames(
                                    active
                                      ? "bg-gray-100 text-gray-900"
                                      : "text-gray-700",
                                    "block px-4 py-3",
                                    "font-semibold flex items-center justify-between cursor-pointer",
                                  )}
                                >
                                  {link.label}{" "}
                                  <ArrowRightCircleIcon className="w-4 h-4 ml-2 text-black sm:w-5 sm:h-5" />
                                </a>
                              )}
                            </Menu.Item>
                          </div>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </>
                )}
              </Menu>
              <Menu
                as="div"
                className="relative hidden text-left lg:inline-block"
              >
                <div>
                  <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 px-3 py-2 font-semibold text-black">
                    Industries
                    <ChevronDownIcon
                      className="w-5 h-5 -mr-1 text-gray-800"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 w-56 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {industryLinks.map((link, index) => (
                      <div key={link.href} className="">
                        {index !== 0 && (
                          <div className="border border-b-0 border-l-0 border-r-0 border-gray-300"></div>
                        )}
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href={link.href}
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-3",
                                "font-semibold flex items-center justify-between cursor-pointer",
                              )}
                            >
                              {link.label}{" "}
                              <ArrowRightCircleIcon className="w-4 h-4 ml-2 text-black sm:w-5 sm:h-5" />
                            </a>
                          )}
                        </Menu.Item>
                      </div>
                    ))}
                  </Menu.Items>
                </Transition>
              </Menu>
              <Menu
                as="div"
                className="relative hidden text-left lg:inline-block"
              >
                <div>
                  <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 px-3 py-2 font-semibold text-black">
                    Partners
                    <ChevronDownIcon
                      className="w-5 h-5 -mr-1 text-gray-800"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 w-56 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {partnerLinks.map((link, index) => (
                      <div key={link.href} className="">
                        {index !== 0 && (
                          <div className="border border-b-0 border-l-0 border-r-0 border-gray-300"></div>
                        )}
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href={link.href}
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-3",
                                "font-semibold flex items-center justify-between cursor-pointer",
                              )}
                            >
                              {link.label}{" "}
                              <ArrowRightCircleIcon className="w-4 h-4 ml-2 text-black sm:w-5 sm:h-5" />
                            </a>
                          )}
                        </Menu.Item>
                      </div>
                    ))}
                  </Menu.Items>
                </Transition>
              </Menu>
              <Link href={`${NEXT_PUBLIC_WWW_URL}/faqs`}>
                <div
                  className={classNames(
                    "font-semibold flex items-center cursor-pointer sm:px-3",
                  )}
                >
                  FAQ
                </div>
              </Link>
              <Menu
                as="div"
                className="relative hidden text-left lg:inline-block"
              >
                <div>
                  <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 px-3 py-2 font-semibold text-black">
                    Company
                    <ChevronDownIcon
                      className="w-5 h-5 -mr-1 text-gray-800"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 w-56 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {companyLinks.map((link, index) => (
                      <div key={link.href} className="py-1">
                        {index !== 0 && (
                          <div
                            key={index}
                            className="border border-b-0 border-l-0 border-r-0 border-gray-300"
                          ></div>
                        )}
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href={link.href}
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2",
                                "font-semibold flex items-center justify-between cursor-pointer",
                              )}
                            >
                              {link.label}{" "}
                              <ArrowRightCircleIcon className="w-4 h-4 ml-2 text-black sm:w-5 sm:h-5" />
                            </a>
                          )}
                        </Menu.Item>
                      </div>
                    ))}
                  </Menu.Items>
                </Transition>
              </Menu>
            </>
          ) : null}

          {showSignUp ? (
            <Button
              variant={ButtonVariant.Orange}
              size={ButtonSize.Normal}
              onClick={scrollToSignUp}
              className="ml-4 md:ml-10 whitespace-nowrap"
            >
              Sign Up
            </Button>
          ) : showSignOut || readCurrentUserAuth ? (
            <div className="flex flex-row items-center gap-3">
              <div className="flex-row items-center hidden gap-3 sm:flex">
                <div>Need Help?</div>
                <div className="flex flex-row items-center">
                  <PhoneIcon className="w-5 h-5 mr-2"></PhoneIcon>
                  <Link href="tel:8778707440">
                    <div className="cursor-pointer whitespace-nowrap">
                      (877) 870-7440
                    </div>
                  </Link>
                </div>
              </div>
              <Button
                onClick={handleSignOut}
                variant={ButtonVariant.Orange}
                size={ButtonSize.Normal}
                className="ml-4 font-medium xl:ml-10 whitespace-nowrap"
              >
                Sign Out
              </Button>
            </div>
          ) : (
            <ButtonLink
              variant={ButtonVariant.Orange}
              size={ButtonSize.Normal}
              href={`${NEXT_PUBLIC_WWW_URL}/signin`}
              className="ml-4 font-medium xl:ml-10 whitespace-nowrap"
            >
              Log In
            </ButtonLink>
          )}
          {showLinks ? (
            <Menu
              as="div"
              className="relative inline-block text-left lg:hidden"
            >
              <div>
                <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 px-3 py-2 font-semibold text-black">
                  <Bars3Icon
                    className="w-5 h-5 -mr-1 text-gray-800"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 w-56 mt-2 max-h-[80vh] overflow-y-auto origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {productLinks.map((link, index) => (
                    <div key={link.href} className="py-0">
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href={link.href}
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block px-4 py-2",
                              "font-semibold flex items-center justify-between cursor-pointer",
                            )}
                          >
                            {link.label}{" "}
                            <ArrowRightCircleIcon className="w-4 h-4 ml-2 text-black sm:w-5 sm:h-5" />
                          </a>
                        )}
                      </Menu.Item>
                    </div>
                  ))}

                  <div
                    key="Industries"
                    className="border border-b-0 border-l-0 border-r-0 border-gray-300"
                  >
                    {industryLinks.map((link, index) => (
                      <div key={link.href} className="py-0">
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href={link.href}
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2",
                                "font-semibold flex justify-between items-center cursor-pointer",
                              )}
                            >
                              {link.label}{" "}
                              <ArrowRightCircleIcon className="w-4 h-4 ml-2 text-black sm:w-5 sm:h-5" />
                            </a>
                          )}
                        </Menu.Item>
                      </div>
                    ))}
                  </div>
                  <div
                    key="Partner Links"
                    className="border border-b-0 border-l-0 border-r-0 border-gray-300"
                  >
                    {partnerLinks.map((link, index) => (
                      <div key={link.href} className="py-0">
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href={link.href}
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2",
                                "font-semibold flex justify-between items-center cursor-pointer",
                              )}
                            >
                              {link.label}{" "}
                              <ArrowRightCircleIcon className="w-4 h-4 ml-2 text-black sm:w-5 sm:h-5" />
                            </a>
                          )}
                        </Menu.Item>
                      </div>
                    ))}
                  </div>
                  <div
                    key="Company Links"
                    className="border border-b-0 border-l-0 border-r-0 border-gray-300"
                  >
                    {companyLinks.map((link, index) => (
                      <div key={link.href} className="py-0">
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href={link.href}
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2",
                                "font-semibold flex items-center justify-between cursor-pointer",
                              )}
                            >
                              {link.label}{" "}
                              <ArrowRightCircleIcon className="w-4 h-4 ml-2 text-black sm:w-5 sm:h-5" />
                            </a>
                          )}
                        </Menu.Item>
                      </div>
                    ))}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          ) : null}
        </div>
      </div>
    </header>
  );
}

export default Header;
