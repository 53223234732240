/** @format */

import { XMarkIcon } from "@heroicons/react/24/outline";
import { ENUM_PRODUCT_NAMES } from "@prisma/client";
import { Dialog } from "primereact/dialog";
import SignUp from "../../signup/signup";

type Props = {
  showSignupModal: boolean;
  setshowSignupModal: (value: boolean) => void;
  productName: ENUM_PRODUCT_NAMES;
};

export function SignupModal({
  showSignupModal,
  setshowSignupModal,
  productName,
}: Props) {
  return (
    <Dialog
      className="fixed inset-0 z-20"
      header={`Signup`}
      style={{ fontFamily: "Inter" }}
      visible={showSignupModal}
      onHide={() => setshowSignupModal(false)}
      closeOnEscape={true}
      closable={false}
    >
      <div className="flex items-center justify-center text-center">
        <div className="relative inline-block w-full p-2 overflow-hidden text-left transition-all transform bg-gray-100 rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
          <button
            type="button"
            className="absolute right-[20px] rounded-md text-primary focus:outline-none"
            onClick={() => {
              setshowSignupModal(false);
            }}
          >
            <span className="sr-only">Close</span>
            <XMarkIcon className="w-6 h-6" aria-hidden="true" />
          </button>

          <SignUp productName={productName} />
        </div>
      </div>
    </Dialog>
  );
}

export default SignupModal;
